<template>
  <div>
    <!-- head -->
    <b-card class="mb-1">
      <!-- titre -->
      <div class="row">
        <div class="col-lg-6 col-md-8">
          <h2>{{ $t("register_operators.title") }}</h2>
          <!-- <br> form_for_current_sector : {{form_for_current_sector}}  -->
          <!-- <br> selected_sector : {{selected_sector}}  -->
        </div>
        <div class="col-lg-6 col-md-4 mb-1 text-right">
          <b-button
            variant="outline-secondary"
            href="/"
          >
            <i class="feather icon-arrow-left" />
            {{ $t("register_operators.back_to_home") }}
          </b-button>
        </div>
      </div>
      <!-- secteur -->
      <b-row v-if="!isRegisteredSuccessfully">
        <!-- secteur -->
        <b-col
          md="12"
          lg="12"
        >
          <b-form-group
            label="Secteur d'activité "
            label-for="secteur"
          >
            <template v-slot:label>
              <span>
                Secteur d'activité
                <RequiredStar />
              </span>
            </template>
            <v-select
              v-model="selected_sector"
              type="text"
              :options="options_secteurs"
              label="title"
              required="true"
              class="select-size-lg"
            />
            <!-- @close="onTypeEtsInputDirty" -->
            <!-- <div v-if="$v.selected_sector.$error">
              <small
                v-if="$v.selected_sector.required.$invalid"
                class="text-danger"
              >Ce champ est requis</small>
            </div> -->
          </b-form-group>
        </b-col>
        <!-- -------------------------- details by sector -------------------------- -->
        <!-- UEH sous secteur -->
        <!-- <b-col
          v-if="selected_sector && selected_sector.code === 'UEH'"
          md="12"
          lg="12"
        >
          <b-form-group label="Votre type d'établissement">
            <b-form-radio-group
              v-model="form_for_current_sector.type_etablissement_id"
              class="demo-inline-spacing"
              name="guide-sector"
            >
              <b-form-radio
                v-for="(type, t_index) in options_type_secteur_hebergement"
                :key="t_index"
                :value="type.id"
              >
                {{ type.title }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col> -->
        <!-- UEGT type -->
        <!-- <b-col
          v-if="selected_sector && selected_sector.code === 'UEGT'"
          md="12"
          lg="12"
        >
          <b-form-group label="Votre type">
            <b-form-radio-group
              v-model="form_for_current_sector.type"
              class="demo-inline-spacing"
              name="guide-type"
            >
              <b-form-radio
                v-for="(type, t_index) in options_type_guide_tourisme"
                :key="t_index"
                :value="type"
              >
                {{ type }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col> -->
        <!-- UEGT catégorie -->
        <!-- <b-col
          v-if="selected_sector && selected_sector.code === 'UEGT'"
          md="12"
          lg="12"
        >
          <b-form-group label="Votre catégorie">
            <b-form-radio-group
              v-model="form_for_current_sector.categorie"
              class="demo-inline-spacing"
              name="radio-inline"
            >
              <b-form-radio
                v-for="(categorie, c_index) in options_categorie_guide_tourisme"
                :key="c_index"
                :value="categorie"
              >
                {{ categorie }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col> -->
        <!-- -------------------------- details by sector -------------------------- -->
      </b-row>
    </b-card>
    <!-- form -->
    <b-card class="mb-0 min-h-78-vh">
      <!-- on register succes -->
      <div
        v-if="isRegisteredSuccessfully"
        class="text-center animate__fadeInRight"
      >
        <i
          class="ri-checkbox-circle-line d-block mx-auto font-10rem text-primary"
        />
        <div>
          <p class="font-bold">
            Votre inscription a été enregistrée avec succès. <br>
          </p>
          <p class="font-bold">
            Un mail vous a été envoyé <br>
            Veuillez vous connecter à votre boîte électronique pour suivre les étapes de confirmation de vos identifiants.
          </p>
          <p class="">
            <b-button
              variant="link"
              :to="{ name: 'home-public' }"
            >
              Retour à la page d'accueil
            </b-button>
            <b-button
              variant="link"
              :to="{ name: 'auth-login' }"
            >
              Se connecter
            </b-button>
          </p>
        </div>
      </div>
      <!-- wizard -->
      <form-wizard
        v-if="selected_sector && loading_form_content && !isRegisteredSuccessfully"
        ref="current-form-wizard"
        :title="'Détails de l\'inscription'"
        color="#0e6158"
        :subtitle="null"
        step-size="xs"
        finish-button-text="Effectuer le transfert"
        :next-button-text="'Étape suivante'"
        :back-button-text="'Étape précédente'"
        class="steps-transparent"
        shape="circle"
        @on-complete="
          () => {
            showRecap();
          }
        "
        :next-button-disabled="isNextButtonDisabled"
      >
        <!-- custom finish button -->
        <template #finish="{ next, previous, canFinish }">
          <button
            type="button"
            class="btn btn-primary"
          >
            <span>
              <!-- {{ $t("button.register") }} -->
              Continuer le processus
            </span>
          </button>
        </template>
        <!-- control RCCM -->
        <tab-content
          v-if="active_sections.rccm.active"
          :title="'RCCM de l\'établissement'"
          :before-change="step_rccm"
          class="no-padding"
          lazy
        >
          <b-card class="">
            <b-row>
              <!-- rccm -->
              <b-col
                md="12"
                lg="12"
              >
                <b-form-group
                  label-for="rccm"
                >
                  <template v-slot:label>
                    <span>
                      {{ $t("register_operators.label_company_rccm") }}
                      <RequiredStar />
                      exemple : RB/COT/23 A 94737
                    </span>
                  </template>
                  <b-input-group
                    :class="have_check_rccm && !rccm_value_valid && 'border border-danger'"
                  >
                    <b-form-input
                      id="rccm"
                      v-model="form_for_current_sector.rccm"
                      :state="$v.form_for_current_sector.rccm.$error ? false : null"
                      name="nom_representant"
                    />
                    <b-input-group-append is-text>
                      <b-spinner
                        v-if="isLoading_check_rccm"
                        small
                      />
                      <i
                        v-else
                        class="las"
                        :class="
                          rccm_value_valid
                            ? 'la-check iconGreen'
                            : 'la-times iconRed'
                        "
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <!-- alerts -->
                  <span> 
                    <div v-if="form_for_current_sector.rccm && !rccm_string_valid">
                      <small
                        class="text-danger"
                      >Ce champ doit respecter le bon format</small>
                    </div>
                    <div v-if="form_for_current_sector.rccm && have_check_rccm && !rccm_value_valid">
                      <small
                        class="text-danger"
                      >Le numéro RCCM n'est pas valide. Veuillez renseigner un numéro valide.</small>
                    </div>
                    <div v-if="$v.form_for_current_sector.rccm.$error">
                      <small
                        v-if="$v.form_for_current_sector.rccm.required.$invalid"
                        class="text-danger"
                      >Ce champ doit repecter le bon format. Exemple:
                        RB/COT/09C755</small>
                    </div>
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-primary"
                @click="change_rccm()"
                size="sm"
              >
                <span>
                  Vérifier le RCCM
                  <b-spinner
                    v-if="isLoading_check_rccm"
                    small
                  />
                </span>
              </button>
            </div>
          </b-card>
        </tab-content>
        <!-- control NPI -->
        <tab-content
          v-if="active_sections.npi.active"
          :title="active_sections.npi.label"
          :before-change="step_npi"
          class="no-padding"
          lazy
        >
          <b-card class="">
            <b-row>
              <!-- npi_representant_legal -->
              <b-col
                md="12"
                lg="12"
              >
                <b-form-group
                  label-for="npi_representant_legal"
                >
                  <template v-slot:label>
                    <span>
                      <!-- {{ $t("register_operators.label_promoter_npi") }} -->
                      {{ labels_for_current_sector.cpi_npi_representant_legal }}
                      <RequiredStar />
                      exemple : 3880872856
                    </span>
                  </template>
                  <b-input-group
                    :class="have_check_npi && !npi_value_valid && 'border border-danger'"
                  >
                    <b-form-input
                      id="npi_representant_legal"
                      v-model="form_for_current_sector.cpi_npi_representant_legal"
                      v-digits-only="{ length: 10 }"
                      :state="$v.form_for_current_sector.cpi_npi_representant_legal.$error ? false : null"
                      name="npi_representant_legal"
                      @blur="$v.form_for_current_sector.cpi_npi_representant_legal.$touch"
                    />
                    <b-input-group-append is-text>
                      <b-spinner
                        v-if="isLoading_check_npi"
                        small
                      />
                      <i
                        v-else
                        class="las"
                        :class="
                          npi_value_valid
                            ? 'la-check iconGreen'
                            : 'la-times iconRed'
                        "
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <!-- alerts -->
                  <span>
                    <div v-if="$v.form_for_current_sector.cpi_npi_representant_legal.$error">
                      <small
                        v-if="$v.form_for_current_sector.cpi_npi_representant_legal.$invalid"
                        class="text-danger"
                      >Ce champs doit avoir 10 caractères</small>
                    </div>
                  </span>
                  
                </b-form-group>
              </b-col>
              <!-- code_npi -->
              <!-- <b-col md="12" lg="6" v-if="!$v.form_for_current_sector.cpi_npi_representant_legal.$error">
                <b-form-group
                  :label="$t('register_operators.label_promoter_code_npi')"
                  label-for="code_npi"
                >
                  <b-form-input
                    id="code_npi"
                    v-model="form_for_current_sector.code_npi"
                    v-phonenumber-field
                    :state="$v.form_for_current_sector.code_npi.$error ? false : null"
                    name="code_npi"
                    autocomplete="off"
                    @blur="$v.form_for_current_sector.code_npi.$touch"
                    @change="change_npi_code_promoteur()"
                  />
                  <div v-if="$v.form_for_current_sector.code_npi.$error">
                    <small
                      v-if="$v.form_for_current_sector.code_npi.required.$invalid"
                      class="text-danger"
                      >Ce champ est requis</small
                    >
                  </div>
                </b-form-group>
              </b-col> -->
            </b-row>
            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-primary"
                @click="change_npi_representant_legal()"
                size="sm"
              >
                <span>
                  Vérifier le NPI
                  <b-spinner
                    v-if="isLoading_check_npi"
                    small
                  />
                </span>
              </button>
            </div>
          </b-card>
        </tab-content>
        <!-- establishment -->
        <tab-content
          v-if="active_sections.establishment.active"
          :title="active_sections.establishment.label"
          :before-change="step_establishment"
          class="no-padding"
          lazy
        >
          <b-card class="">
            <!-- non guide de tourisme  -->
            <b-row v-if="!is_UEGT_sector_selected">
              <!-- nom_ets -->
              <b-col
                md="12"
                lg="6"
              >
                <b-form-group
                  :label="$t('register_operators.label_company_name')"
                  label-for="nomEts"
                >
                  <template v-slot:label>
                    <span>{{ $t("register_operators.label_company_name") }}
                      <RequiredStar />
                    </span>
                  </template>
                  <b-form-input
                    id="nomEts"
                    v-model="form_for_current_sector.nom_ets"
                    :state="$v.form_for_current_sector.nom_ets.$error ? false : null"
                    name="nomEts"
                    @blur="$v.form_for_current_sector.nom_ets.$touch"
                  />
                  <div v-if="$v.form_for_current_sector.nom_ets.$error">
                    <small
                      v-if="$v.form_for_current_sector.nom_ets.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                  </div>
                </b-form-group>
              </b-col>
              <!-- adresse_Ets -->
              <b-col
                md="12"
                lg="6"
              >
                <b-form-group
                  :label="$t('register_operators.label_company_adress')"
                  label-for="adresse_Ets"
                >
                  <template v-slot:label>
                    <span>{{ $t("register_operators.label_company_adress") }}
                      <RequiredStar />
                    </span>
                  </template>
                  <b-form-input
                    id="adresse_Ets"
                    v-model="form_for_current_sector.adresse_ets"
                    :state="$v.form_for_current_sector.adresse_ets.$error ? false : null"
                    name="adresse_Ets"
                    @blur="$v.form_for_current_sector.adresse_ets.$touch"
                  />
                  <div v-if="$v.form_for_current_sector.adresse_ets.$error">
                    <small
                      v-if="$v.form_for_current_sector.adresse_ets.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                  </div>
                </b-form-group>
              </b-col>
              <!-- ifu -->
              <b-col
                md="12"
                lg="4"
              >
                <b-form-group
                  :label="$t('register_operators.label_company_ifu')"
                  label-for="ifu"
                >
                  <b-form-input
                    id="ifu"
                    v-model="form_for_current_sector.ifu"
                    v-digits-only="{ length: 13 }"
                    name="ifu"
                    :state="$v.form_for_current_sector.ifu.$error ? false : null"
                    @blur="$v.form_for_current_sector.ifu.$touch"
                  />
                  <div v-if="$v.form_for_current_sector.ifu.$error">
                    <small
                      v-if="$v.form_for_current_sector.ifu.$invalid"
                      class="text-danger"
                    >Ce champs doit avoir 13 caractères</small>
                  </div>
                </b-form-group>
              </b-col>
              <!-- telephone_ets -->
              <b-col
                md="12"
                lg="4"
              >
                <b-form-group
                  :label="$t('register_operators.label_company_phone_number')"
                  label-for="telephone_ets"
                >
                  <template v-slot:label>
                    <span>{{ $t("register_operators.label_company_phone_number") }}
                      <RequiredStar />
                    </span>
                  </template>
                  <vue-tel-input
                    id="telephone_ets"
                    v-model="form_for_current_sector.telephone_ets"
                    v-phonenumber-field
                    name="telephone_ets"
                    :state="$v.form_for_current_sector.telephone_promoteur.$error ? false : null"
                    class="padng"
                    :input-options="inputOptions"
                    default-country="BJ"
                    :required="true"
                    placeholder="Veuillez saisir votre numero de téléphone"
                    @blur="$v.form_for_current_sector.telephone_ets.$touch"
                  />
                  <div v-if="$v.form_for_current_sector.telephone_ets.$error">
                    <small
                      v-if="$v.form_for_current_sector.telephone_ets.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                  </div>
                </b-form-group>
              </b-col>
              <!-- email_ets -->
              <b-col
                md="12"
                lg="4"
              >
                <b-form-group>
                  <label for="email_ets">
                    <span>
                      {{ $t("register_operators.label_company_email") }}
                      <RequiredStar />
                    </span>
                  </label>
                  <b-form-input
                    id="email_ets"
                    v-model="form_for_current_sector.email_ets"
                    :state="$v.form_for_current_sector.email_ets.$error ? false : null"
                    name="email_ets"
                    @blur="$v.form_for_current_sector.email_ets.$touch"
                  />
                  <div v-if="$v.form_for_current_sector.email_ets.$error">
                    <small
                      v-if="$v.form_for_current_sector.email_ets.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                    <small
                      v-if="$v.form_for_current_sector.email_ets.email.$invalid"
                      class="text-danger"
                    >Adresse email invalide</small>
                  </div>
                </b-form-group>
              </b-col>
              <!-- nbre_unites_logements -->
              <b-col
                v-if="is_UEH_sector_selected"
                md="12"
                lg="6"
              >
                <b-form-group
                  :label="$t('register_operators.label_company_accommodation')"
                  label-for="web_site"
                >
                  <b-form-input
                    id="ifu"
                    v-model="form_for_current_sector.nbre_unites_logements"
                    v-digits-only="{ length: 13 }"
                    name="ifu"
                    :state="$v.form_for_current_sector.nbre_unites_logements.$error ? false : null"
                    @blur="$v.form_for_current_sector.nbre_unites_logements.$touch"
                  />
                  <div v-if="$v.form_for_current_sector.nbre_unites_logements.$error">
                    <small
                      v-if="$v.form_for_current_sector.nbre_unites_logements.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                  </div>
                </b-form-group>
              </b-col>
              <!-- nbre_couverts -->
              <b-col
                v-if="is_UER_sector_selected"
                md="12"
                lg="6"
              >
                <b-form-group
                  :label="$t('register_operators.label_company_cutlery')"
                  label-for="web_site"
                >
                  <b-form-input
                    id="ifu"
                    v-model="form_for_current_sector.nbre_couverts"
                    v-digits-only="{ length: 13 }"
                    name="ifu"
                    :state="$v.form_for_current_sector.nbre_couverts.$error ? false : null"
                    @blur="$v.form_for_current_sector.nbre_couverts.$touch"
                  />
                  <div v-if="$v.form_for_current_sector.nbre_couverts.$error">
                    <small
                      v-if="$v.form_for_current_sector.nbre_couverts.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                  </div>
                </b-form-group>
              </b-col>
              <!-- site_web -->
              <b-col
                md="12"
                lg="6"
              >
                <b-form-group
                  :label="$t('register_operators.label_company_web_site')"
                  label-for="web_site"
                >
                  <b-form-input
                    id="web_site"
                    v-model="form_for_current_sector.web_site"
                    name="web_site"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- guide section ------------------------------------------------------->
            <b-row v-if="is_UEGT_sector_selected">
              <span
                v-if="is_UEGT_sector_selected"
                class="col-12 row"
              >
                <!-- nom_representant -->
                <b-col
                  md="12"
                  lg="3"
                >
                  <b-form-group
                    :label="$t('register_operators.label_promoter_lastname')"
                    label-for="nom_representant"
                  >
                    <template v-slot:label>
                      <span>{{ $t("register_operators.label_promoter_lastname") }}
                        <RequiredStar />
                      </span>
                    </template>
                    <b-form-input
                      id="nom_representant"
                      v-model="form_for_current_sector.nom_representant"
                      :state="$v.form_for_current_sector.nom_representant.$error ? false : null"
                      name="nom_representant"
                      :disabled="form_for_current_sector.nom_representant !== null"
                      @blur="$v.form_for_current_sector.nom_representant.$touch"
                    />
                    <div v-if="$v.form_for_current_sector.nom_representant.$error">
                      <small
                        v-if="$v.form_for_current_sector.nom_representant.required.$invalid"
                        class="text-danger"
                      >Ce champ est requis</small>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- prenoms_representant -->
                <b-col
                  md="12"
                  lg="3"
                >
                  <b-form-group
                    :label="$t('register_operators.label_promoter_firstname')"
                    label-for="prenoms_representant"
                  >
                    <template v-slot:label>
                      <span>{{ $t("register_operators.label_promoter_firstname") }}
                        <RequiredStar />
                      </span>
                    </template>
                    <b-form-input
                      id="prenoms_representant"
                      v-model="form_for_current_sector.prenoms_representant"
                      :state="$v.form_for_current_sector.prenoms_representant.$error ? false : null"
                      name="prenoms_representant"
                      :disabled="form_for_current_sector.prenoms_representant !== null"
                      @blur="$v.form_for_current_sector.prenoms_representant.$touch"
                    />
                    <div v-if="$v.form_for_current_sector.prenoms_representant.$error">
                      <small
                        v-if="$v.form_for_current_sector.prenoms_representant.required.$invalid"
                        class="text-danger"
                      >Ce champ est requis</small>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- sexe_representant -->
                <b-col
                  md="12"
                  lg="3"
                >
                  <b-form-group
                    :label="$t('register_operators.label_promoter_sex')"
                    label-for="sexe_representant"
                  >
                    <template v-slot:label>
                      <span>{{ $t("register_operators.label_promoter_sex") }}
                        <RequiredStar />
                      </span>
                    </template>
                    <v-select
                      id="sexe_representant"
                      v-model="form_for_current_sector.sexe_representant"
                      type="text"
                      :options="sexe_list"
                      required="true"
                      class="select-size-xl"
                      :class="
                        $v.form_for_current_sector.sexe_representant.$error ? 'invalid-input' : ''
                      "
                      :disabled="form_for_current_sector.sexe_representant !== null"
                    />
                    <div v-if="$v.form_for_current_sector.sexe_representant.$error">
                      <small
                        v-if="$v.form_for_current_sector.sexe_representant.required.$invalid"
                        class="text-danger"
                      >Ce champ est requis</small>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- telephone_representant -->
                <b-col
                  md="12"
                  lg="3"
                >
                  <b-form-group
                    :label="$t('register_operators.label_promoter_phone')"
                    label-for="telephone_representant"
                  >
                    <template v-slot:label>
                      <span>{{ $t("register_operators.label_promoter_phone") }}
                        <RequiredStar />
                      </span>
                    </template>
                    <b-form-input
                      id="telephone_representant"
                      v-model="form_for_current_sector.telephone_representant"
                      :state="$v.form_for_current_sector.telephone_representant.$error ? false : null"
                      name="telephone_representant"
                      :disabled="form_for_current_sector.telephone_representant !== null"
                      @blur="$v.form_for_current_sector.telephone_representant.$touch"
                    />
                    <div v-if="$v.form_for_current_sector.telephone_representant.$error">
                      <small
                        v-if="$v.form_for_current_sector.telephone_representant.required.$invalid"
                        class="text-danger"
                      >Ce champ est requis</small>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- nationalite_representant -->
                <b-col
                  md="12"
                  lg="6"
                >
                  <b-form-group
                    :label="$t('register_operators.label_promoter_nationality')"
                    label-for="nationalite_representant"
                  >
                    <template v-slot:label>
                      <span>{{ $t("register_operators.label_promoter_nationality") }}
                        <RequiredStar />
                      </span>
                    </template>
                    <v-select
                      id="nationalite_representant"
                      v-model="form_for_current_sector.nationalite_representant"
                      type="text"
                      :options="pays"
                      required="true"
                      class="select-size-xl"
                      :class="$v.form_for_current_sector.nationalite_representant.$error ? 'invalid-input' : ''"
                    />
                    <div v-if="$v.form_for_current_sector.nationalite_representant.$error">
                      <small
                        v-if="$v.form_for_current_sector.nationalite_representant.required.$invalid"
                        class="text-danger"
                      >Ce champ est requis</small>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- site_web -->
                <b-col
                  md="12"
                  lg="6"
                >
                  <b-form-group
                    :label="$t('register_operators.label_company_web_site')"
                    label-for="web_site"
                  >
                    <b-form-input
                      id="web_site"
                      v-model="form_for_current_sector.web_site"
                      name="web_site"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                  lg="12"
                >
                  <DepartementCommuneArrondissementVillageForm
                    :trigger-form-validations="triggerDepartementFormValidations"
                    @input="onDepartementFormChange"
                  />
                </b-col>
                <!-- email_representant -->
                <b-col
                  md="12"
                  lg="4"
                >
                  <b-form-group
                    :label="$t('register_operators.label_promoter_email')"
                    label-for="email_representant"
                  >
                    <template v-slot:label>
                      <span>{{ $t("register_operators.label_promoter_email") }}
                        <RequiredStar />
                      </span>
                    </template>
                    <b-form-input
                      id="email_representant"
                      v-model="form_for_current_sector.email_representant"
                      name="email_representant"
                      :state="$v.form_for_current_sector.email_representant.$error ? false : null"
                      @blur="$v.form_for_current_sector.email_representant.$touch"
                    />
                    <div v-if="$v.form_for_current_sector.email_representant.$error">
                      <small
                        v-if="$v.form_for_current_sector.email_representant.required.$invalid"
                        class="text-danger"
                      >Ce champ est requis</small>
                      <small
                        v-if="$v.form_for_current_sector.email_representant.email.$invalid"
                        class="text-danger"
                      >Adresse email invalide</small>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- password -->
                <b-col
                  md="12"
                  lg="4"
                >
                  <b-form-group
                    label-for="register-password"
                    :label="$t('register_operators.label_password')"
                    class="password-field"
                  >
                    <template v-slot:label>
                      <span>{{ $t("register_operators.label_password") }}
                        <RequiredStar />
                      </span>
                    </template>
                    <b-input-group
                      class="input-group-merge"
                      :class="$v.form_for_current_sector.password.$error ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="register-password"
                        v-model="form_for_current_sector.password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="$v.form_for_current_sector.password.$error ? false : null"
                        name="register-password"
                        placeholder="············"
                        @focus="passwordFieldIsFocused = true"
                        @blur="
                          passwordFieldIsFocused = false;
                          $v.form_for_current_sector.password.$touch;
                        "
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="
                            passwordFieldType === 'password'
                              ? 'EyeIcon'
                              : 'EyeOffIcon'
                          "
                          class="cursor-pointer"
                          @click="togglePasswordInputType"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <div
                      v-if="passwordFieldIsFocused || $v.form_for_current_sector.password.$error"
                      class="invalid-feedback password-rules"
                    >
                      <small
                        v-if="
                          passwordFieldIsFocused ||
                            $v.form_for_current_sector.password.isValid.$invalid
                        "
                      >
                        <span class="label">
                          Le mot de passe doit contenir :</span>
                        <ul class="list-style-none password-validators-errors">
                          <li
                            :class="{
                              valid: passwordValidators(form_for_current_sector.password)
                                .atLeastOneCapLetter,
                            }"
                          >
                            <div class="icons">
                              <i
                                v-if="
                                  !passwordValidators(form_for_current_sector.password)
                                    .atLeastOneCapLetter
                                "
                                class="las la-times-circle"
                              />
                              <i
                                v-if="
                                  passwordValidators(form_for_current_sector.password)
                                    .atLeastOneCapLetter
                                "
                                class="las la-check-circle"
                              />
                            </div>
                            au moins une lettre majuscule
                          </li>
                          <li
                            :class="{
                              valid: passwordValidators(form_for_current_sector.password)
                                .atLeastOneMinusLetter,
                            }"
                          >
                            <div class="icons">
                              <i
                                v-if="
                                  !passwordValidators(form_for_current_sector.password)
                                    .atLeastOneMinusLetter
                                "
                                class="las la-times-circle"
                              />
                              <i
                                v-if="
                                  passwordValidators(form_for_current_sector.password)
                                    .atLeastOneMinusLetter
                                "
                                class="las la-check-circle"
                              />
                            </div>
                            au moins une lettre minuscule
                          </li>
                          <li
                            :class="{
                              valid: passwordValidators(form_for_current_sector.password)
                                .atLeastOneDigits,
                            }"
                          >
                            <div class="icons">
                              <i
                                v-if="
                                  !passwordValidators(form_for_current_sector.password)
                                    .atLeastOneDigits
                                "
                                class="las la-times-circle"
                              />
                              <i
                                v-if="
                                  passwordValidators(form_for_current_sector.password)
                                    .atLeastOneDigits
                                "
                                class="las la-check-circle"
                              />
                            </div>
                            au moins un chiffre
                          </li>
                          <li
                            :class="{
                              valid: passwordValidators(form_for_current_sector.password)
                                .atLeastOneSpecialChar,
                            }"
                          >
                            <div class="icons">
                              <i
                                v-if="
                                  !passwordValidators(form_for_current_sector.password)
                                    .atLeastOneSpecialChar
                                "
                                class="las la-times-circle"
                              />
                              <i
                                v-if="
                                  passwordValidators(form_for_current_sector.password)
                                    .atLeastOneSpecialChar
                                "
                                class="las la-check-circle"
                              />
                            </div>
                            au moins un caractère special (@#$%^&+=)
                          </li>
                          <li
                            :class="{
                              valid: passwordValidators(form_for_current_sector.password)
                                .minimumLength,
                            }"
                          >
                            <div class="icons">
                              <i
                                v-if="
                                  !passwordValidators(form_for_current_sector.password).minimumLength
                                "
                                class="las la-times-circle"
                              />
                              <i
                                v-if="
                                  passwordValidators(form_for_current_sector.password).minimumLength
                                "
                                class="las la-check-circle"
                              />
                            </div>
                            au moins 8 caractères
                          </li>
                        </ul>
                      </small>
                    </div>
                  </b-form-group>
                </b-col>
                <!-- confirm password -->
                <b-col
                  md="12"
                  lg="4"
                >
                  <b-form-group
                    label-for="register-confirm-password"
                    :label="$t('register_operators.label_confirm_password')"
                  >
                    <template v-slot:label>
                      <span>{{ $t("register_operators.label_confirm_password") }}
                        <RequiredStar />
                      </span>
                    </template>
                    <b-input-group
                      class="input-group-merge"
                      :class="
                        $v.form_for_current_sector.confirmPassword.$error ? 'is-invalid' : null
                      "
                    >
                      <b-form-input
                        id="register-confirm-password"
                        v-model="form_for_current_sector.confirmPassword"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="$v.form_for_current_sector.confirmPassword.$error ? false : null"
                        name="register-confirm-password"
                        placeholder="············"
                        @blur="$v.form_for_current_sector.confirmPassword.$touch"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="
                            passwordFieldType === 'password'
                              ? 'EyeIcon'
                              : 'EyeOffIcon'
                          "
                          class="cursor-pointer"
                          @click="togglePasswordInputType"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <div v-if="$v.form_for_current_sector.confirmPassword.$error">
                      <small
                        v-if="$v.form_for_current_sector.confirmPassword.isValid.$invalid"
                        class="text-danger"
                      >Les mots de passe ne sont pas identiques</small>
                    </div>
                  </b-form-group>
                </b-col>
              </span>
            </b-row>
            <!-- /guide section ------------------------------------------------------->
            <span v-if="!is_UEGT_sector_selected">
              <DepartementCommuneArrondissementVillageForm
                :trigger-form-validations="triggerDepartementFormValidations"
                @input="onDepartementFormChange"
              />
            </span>
            <!-- <b-row class="col-12">
            </b-row> -->
            <hr class="hr-primary">
            <b-row>
              <!-- fichiers -->
              <b-col
                md="12"
                lg="12"
                class="row"
              >
                <label
                  v-if="is_UEGT_sector_selected"
                  class="col-12"
                >{{ $t("register_operators.label_photo_uegt") }} </label>
                <label
                  v-if="is_UEAV_sector_selected"
                  class="col-12"
                  for=""
                >{{ $t("register_operators.label_photo_ueav") }}</label>
                <label
                  v-if="is_UEH_sector_selected || is_UER_sector_selected"
                  class="col-12"
                  for=""
                >{{ $t("register_operators.label_photo_ueh") }}</label>
                <b-form-group
                  v-for="(fileInput, index) in piecesJointesList"
                  :key="index"
                  :label-for="fileInput.label + index"
                  :label="fileInput.label"
                  class="col-4"
                >
                  <!-- Styled -->
                  <b-form-file
                    v-model="piecesJointesList[index].value"
                    placeholder="Veuillez choisir un fichier(<1mo)"
                    drop-placeholder="Déposez ici..."
                    accept="image/jpeg,image/png"
                    :state="
                      !isValidFile(piecesJointesList[index].value)
                        ? false
                        : null
                    "
                    @change="(event) => handleFileChange(event, index)"
                  />
                  <div>
                    <small
                      v-if="!isValidFile(piecesJointesList[index].value)"
                      class="text-danger"
                    >
                      La taille de ce fichier dépasse 1Mo
                    </small>
                  </div>
                  <!-- {{ file_for_vizualisation }} -->
                  <span>
                    <img
                      v-if="piecesJointesList[index].fileContent"
                      :src="piecesJointesList[index].fileContent"
                      alt="Image"
                      style="width: 100%; height: 50vh"
                      class="img-fluid required-img"
                    >
                  </span>
                </b-form-group>
              </b-col>
              <hr class="hr-primary">
              <hr class="hr-primary">

              <!-- localisation -->
              <!-- v-if="!is_UEGT_sector_selected" -->
              <b-col
                md="12"
                lg="12"
              >
                <label classe="">
                  Marquez la position de votre établissement. <br> Merci de vous assurer que votre position correspond à celle de votre établissement <br>
                  <span v-if="form && form_for_current_sector.localization && form_for_current_sector.localization.lng && form_for_current_sector.localization.lat">
                    {{ form_for_current_sector.localization.lng && form_for_current_sector.localization.lat && 'Votre position a été bien récupérée.' }}
                    <!-- {{ form_for_current_sector.localization.lng &&  'longitude: ' + form_for_current_sector.localization.lng }}
                    {{ form_for_current_sector.localization.lat &&  'latitude: ' + form_for_current_sector.localization.lat }} -->
                  </span>
                </label>
                <MapsComponent
                 map-id="map1"
                  @on_position_fixed="position_selected($event)"
                />
              </b-col>
            </b-row>
          </b-card>
        </tab-content>
        <!-- promotor -->
        <tab-content
          v-if="active_sections.promotor.active"
          :title="active_sections.promotor.label"
          :before-change="step_promotor"
          class="no-padding"
          lazy
        >
          <b-card class="">
            <b-row>
              <!-- nom_promoteur -->
              <b-col
                md="12"
                lg="2"
              >
                <b-form-group
                  :label="$t('register_operators.label_promoter_lastname')"
                  label-for="nom_promoteur"
                >
                  <template v-slot:label>
                    <span>{{ $t("register_operators.label_promoter_lastname") }}
                      <RequiredStar />
                    </span>
                  </template>
                  <b-form-input
                    id="nom_promoteur"
                    v-model="form_for_current_sector.nom_promoteur"
                    :state="$v.form_for_current_sector.nom_promoteur.$error ? false : null"
                    name="nom_promoteur"
                    @blur="$v.form_for_current_sector.nom_promoteur.$touch"
                  />
                  <div v-if="$v.form_for_current_sector.nom_promoteur.$error">
                    <small
                      v-if="$v.form_for_current_sector.nom_promoteur.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                  </div>
                </b-form-group>
              </b-col>
              <!-- prenoms_promoteur -->
              <b-col
                md="12"
                lg="4"
              >
                <b-form-group
                  :label="$t('register_operators.label_promoter_firstname')"
                  label-for="prenoms_promoteur"
                >
                  <template v-slot:label>
                    <span>{{ $t("register_operators.label_promoter_firstname") }}
                      <RequiredStar />
                    </span>
                  </template>
                  <b-form-input
                    id="prenoms_promoteur"
                    v-model="form_for_current_sector.prenoms_promoteur"
                    :state="$v.form_for_current_sector.prenoms_promoteur.$error ? false : null"
                    name="prenoms_promoteur"
                    @blur="$v.form_for_current_sector.prenoms_promoteur.$touch"
                  />
                  <div v-if="$v.form_for_current_sector.prenoms_promoteur.$error">
                    <small
                      v-if="$v.form_for_current_sector.prenoms_promoteur.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                  </div>
                </b-form-group>
              </b-col>
              <!-- sexe_promoteur -->
              <b-col
                md="12"
                lg="3"
              >
                <b-form-group
                  :label="$t('register_operators.label_promoter_sex')"
                  label-for="sexe_promoteur"
                >
                  <template v-slot:label>
                    <span>{{ $t("register_operators.label_promoter_sex") }}
                      <RequiredStar />
                    </span>
                  </template>
                  <v-select
                    id="sexe_promoteur"
                    v-model="form_for_current_sector.sexe_promoteur"
                    type="text"
                    :options="sexe_list"
                    required="true"
                    class="select-size-xl"
                    :class="
                      $v.form_for_current_sector.sexe_promoteur.$error ? 'invalid-input' : ''
                    "
                  />
                  <div v-if="$v.form_for_current_sector.sexe_promoteur.$error">
                    <small
                      v-if="$v.form_for_current_sector.sexe_promoteur.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                  </div>
                </b-form-group>
              </b-col>
              <!-- nationalite_promoteur -->
              <b-col
                md="12"
                lg="3"
              >
                <b-form-group
                  :label="$t('register_operators.label_promoter_nationality')"
                  label-for="nationalite_promoteur"
                >
                  <template v-slot:label>
                    <span>{{ $t("register_operators.label_promoter_nationality") }}
                      <RequiredStar />
                    </span>
                  </template>
                  <v-select
                    id="nationalite_promoteur"
                    v-model="form_for_current_sector.nationalite_promoteur"
                    type="text"
                    :options="pays"
                    required="true"
                    class="select-size-xl"
                    :class="$v.form_for_current_sector.nationalite_promoteur.$error ? 'invalid-input' : ''"
                  />
                  <div v-if="$v.form_for_current_sector.nationalite_promoteur.$error">
                    <small
                      v-if="$v.form_for_current_sector.nationalite_promoteur.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                  </div>
                </b-form-group>
              </b-col>
              <!-- telephone_promoteur -->
              <b-col
                md="12"
                lg="6"
              >
                <b-form-group
                  :label="$t('register_operators.label_promoter_phone')"
                  label-for="telephone_promoteur"
                >
                  <template v-slot:label>
                    <span>{{ $t("register_operators.label_promoter_phone") }}
                      <RequiredStar />
                    </span>
                  </template>
                  <!-- <phone-number-input
                    v-model="form_for_current_sector.telephone_promoteur"
                    :fieldId="'phone-number'"
                    required
                    default-country-code="BJ"
                    :translations="customTranslations"
                  /> -->
                  <vue-tel-input
                    id="telephone_promoteur"
                    v-model="form_for_current_sector.telephone_promoteur"
                    v-phonenumber-field
                    placeholder="Veuillez saisir le numero de téléphone du promoteur"
                    :input-options="inputOptions"
                    default-country="BJ"
                    :state="$v.form_for_current_sector.telephone_promoteur.$error ? false : null"
                    name="telephone_promoteur"
                    class="padng"
                    :required="true"
                    @blur="$v.form_for_current_sector.telephone_promoteur.$touch"
                  />
                  <!--  <b-form-input id="telephone_promoteur" v-model="form_for_current_sector.telephone_promoteur" v-phonenumber-field
                    :state="$v.form_for_current_sector.telephone_promoteur.$error ? false : null" name="telephone_promoteur"
                    autocomplete="off" @blur="$v.form_for_current_sector.telephone_promoteur.$touch" /> -->
                  <div v-if="$v.form_for_current_sector.telephone_promoteur.$error">
                    <small
                      v-if="$v.form_for_current_sector.telephone_promoteur.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                  </div>
                </b-form-group>
              </b-col>
              <!-- email_promoteur -->
              <b-col
                md="12"
                lg="6"
              >
                <b-form-group
                  :label="$t('register_operators.label_promoter_email')"
                  label-for="email_promoteur"
                >
                  <template v-slot:label>
                    <span>{{ $t("register_operators.label_promoter_email") }}
                      <RequiredStar />
                    </span>
                  </template>
                  <b-form-input
                    id="email_promoteur"
                    v-model="form_for_current_sector.email_promoteur"
                    name="email_promoteur"
                    :state="$v.form_for_current_sector.email_promoteur.$error ? false : null"
                    @blur="$v.form_for_current_sector.email_promoteur.$touch"
                  />
                  <div v-if="$v.form_for_current_sector.email_promoteur.$error">
                    <small
                      v-if="$v.form_for_current_sector.email_promoteur.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                    <small
                      v-if="$v.form_for_current_sector.email_promoteur.email.$invalid"
                      class="text-danger"
                    >Adresse email invalide</small>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </tab-content>
        <!-- connexion_informations -->
        <tab-content
          v-if="active_sections.connexion_informations.active"
          :title="active_sections.connexion_informations.label"
          class="no-padding"
          lazy
        >
          <b-card class="">
            <b-row>

              <!-- nom_representant -->
              <b-col
                md="12"
                lg="2"
              >
                <b-form-group
                  :label="$t('register_operators.label_manager_lastname')"
                  label-for="nom_representant"
                >
                  <template v-slot:label>
                    <span>{{ $t("register_operators.label_manager_lastname") }}
                      <RequiredStar />
                    </span>
                  </template>
                  <b-form-input
                    id="nom_representant"
                    v-model="form_for_current_sector.nom_representant"
                    :state="$v.form_for_current_sector.nom_representant.$error ? false : null"
                    name="nom_representant"
                    @blur="$v.form_for_current_sector.nom_representant.$touch"
                    :disabled="form_for_current_sector.nom_representant !== null"
                  />
                  <div v-if="$v.form_for_current_sector.nom_representant.$error">
                    <small
                      v-if="$v.form_for_current_sector.nom_representant.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                  </div>
                </b-form-group>
              </b-col>
              <!-- prenoms_representant -->
              <b-col
                md="12"
                lg="4"
              >
                <b-form-group
                  :label="$t('register_operators.label_manager_firstname')"
                  label-for="prenoms_representant"
                >
                  <template v-slot:label>
                    <span>{{ $t("register_operators.label_manager_firstname") }}
                      <RequiredStar />
                    </span>
                  </template>
                  <b-form-input
                    id="prenoms_representant"
                    v-model="form_for_current_sector.prenoms_representant"
                    :state="$v.form_for_current_sector.prenoms_representant.$error ? false : null"
                    name="prenoms_representant"
                    @blur="$v.form_for_current_sector.prenoms_representant.$touch"
                    :disabled="form_for_current_sector.prenoms_representant !== null"
                  />
                  <div v-if="$v.form_for_current_sector.prenoms_representant.$error">
                    <small
                      v-if="$v.form_for_current_sector.prenoms_representant.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                  </div>
                </b-form-group>
              </b-col>
              <!-- sexe_representant -->
              <b-col
                md="12"
                lg="3"
              >
                <b-form-group
                  :label="$t('register_operators.label_manager_sex')"
                  label-for="sexe_representant"
                >
                  <template v-slot:label>
                    <span>{{ $t("register_operators.label_manager_sex") }}
                      <RequiredStar />
                    </span>
                  </template>
                  <v-select
                    id="sexe_representant"
                    v-model="form_for_current_sector.sexe_representant"
                    type="text"
                    :options="sexe_list"
                    required="true"
                    class="select-size-xl"
                    :class="
                      $v.form_for_current_sector.sexe_representant.$error ? 'invalid-input' : ''
                    "
                    :disabled="form_for_current_sector.sexe_representant !== null"
                  />
                  <div v-if="$v.form_for_current_sector.sexe_representant.$error">
                    <small
                      v-if="$v.form_for_current_sector.sexe_representant.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                  </div>
                </b-form-group>
              </b-col> 
              <!-- telephone_representant -->
              <b-col
                md="12"
                lg="3"
              >
                <b-form-group
                  :label="$t('register_operators.label_promoter_phone')"
                  label-for="telephone_representant"
                >
                  <template v-slot:label>
                    <span>{{ $t("register_operators.label_promoter_phone") }}
                      <RequiredStar />
                    </span>
                  </template>
                  <b-form-input
                    id="telephone_representant"
                    v-model="form_for_current_sector.telephone_representant"
                    :state="$v.form_for_current_sector.telephone_representant.$error ? false : null"
                    name="telephone_representant"
                    :disabled="form_for_current_sector.telephone_representant !== null"
                    @blur="$v.form_for_current_sector.telephone_representant.$touch"
                  />
                  <div v-if="$v.form_for_current_sector.telephone_representant.$error">
                    <small
                      v-if="$v.form_for_current_sector.telephone_representant.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                  </div>
                </b-form-group>
              </b-col>
              <!-- nationalite_representant -->
              <b-col
                md="12"
                lg="6"
              >
                <b-form-group
                  :label="$t('register_operators.label_manager_nationality')"
                  label-for="nationalite_representant"
                >
                  <template v-slot:label>
                    <span>{{ $t("register_operators.label_manager_nationality") }}
                      <RequiredStar />
                    </span>
                  </template>
                  <v-select
                    id="nationalite_representant"
                    v-model="form_for_current_sector.nationalite_representant"
                    type="text"
                    :options="pays"
                    required="true"
                    class="select-size-xl"
                    :class="
                      $v.form_for_current_sector.nationalite_representant.$error
                        ? 'invalid-input'
                        : ''
                    "
                  />
                  <div v-if="$v.form_for_current_sector.nationalite_representant.$error">
                    <small
                      v-if="$v.form_for_current_sector.nationalite_representant.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                  </div>
                </b-form-group>
              </b-col> 
            </b-row>


            <hr class="my-3">
            <b-row>
              <!-- email_ets -->
              <b-col
                md="12"
                lg="4"
              >
                <b-form-group
                  label-for="email_ets"
                >
                  <template v-slot:label>
                    <span>
                      {{ 'Email de connexion' }}
                      <RequiredStar />
                    </span>
                  </template>
                  <b-form-input
                    id="email_ets"
                    name="email_ets"
                    v-model="form_for_current_sector.email_ets"
                    :disabled=true
                  />
                </b-form-group>
              </b-col> 
              <!-- password section-->
              <b-col
                md="12"
                lg="4"
              >
                <!-- password -->
                <b-form-group
                  label-for="register-password"
                  :label="$t('register_operators.label_password')"
                  class="password-field"
                >
                  <template v-slot:label>
                    <span>{{ $t("register_operators.label_password") }}
                      <RequiredStar />
                    </span>
                  </template>
                  <b-input-group
                    class="input-group-merge"
                    :class="$v.form_for_current_sector.password.$error ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="form_for_current_sector.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="$v.form_for_current_sector.password.$error ? false : null"
                      name="register-password"
                      placeholder="············"
                      @focus="passwordFieldIsFocused = true"
                      @blur="
                        passwordFieldIsFocused = false;
                        $v.form_for_current_sector.password.$touch;
                      "
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="
                          passwordFieldType === 'password'
                            ? 'EyeIcon'
                            : 'EyeOffIcon'
                        "
                        class="cursor-pointer"
                        @click="togglePasswordInputType"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <div
                    v-if="passwordFieldIsFocused || $v.form_for_current_sector.password.$error"
                    class="invalid-feedback password-rules"
                  >
                    <!-- <small
                      v-if="$v.form_for_current_sector.password.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small> -->
                    <small
                      v-if="
                        passwordFieldIsFocused ||
                          $v.form_for_current_sector.password.isValid.$invalid
                      "
                    >
                      <span class="label">
                        Le mot de passe doit contenir :</span>
                      <ul class="list-style-none password-validators-errors">
                        <li
                          :class="{
                            valid: passwordValidators(form_for_current_sector.password)
                              .atLeastOneCapLetter,
                          }"
                        >
                          <div class="icons">
                            <i
                              v-if="
                                !passwordValidators(form_for_current_sector.password)
                                  .atLeastOneCapLetter
                              "
                              class="las la-times-circle"
                            />
                            <i
                              v-if="
                                passwordValidators(form_for_current_sector.password)
                                  .atLeastOneCapLetter
                              "
                              class="las la-check-circle"
                            />
                          </div>
                          au moins une lettre majuscule
                        </li>
                        <li
                          :class="{
                            valid: passwordValidators(form_for_current_sector.password)
                              .atLeastOneMinusLetter,
                          }"
                        >
                          <div class="icons">
                            <i
                              v-if="
                                !passwordValidators(form_for_current_sector.password)
                                  .atLeastOneMinusLetter
                              "
                              class="las la-times-circle"
                            />
                            <i
                              v-if="
                                passwordValidators(form_for_current_sector.password)
                                  .atLeastOneMinusLetter
                              "
                              class="las la-check-circle"
                            />
                          </div>
                          au moins une lettre minuscule
                        </li>
                        <li
                          :class="{
                            valid: passwordValidators(form_for_current_sector.password)
                              .atLeastOneDigits,
                          }"
                        >
                          <div class="icons">
                            <i
                              v-if="
                                !passwordValidators(form_for_current_sector.password)
                                  .atLeastOneDigits
                              "
                              class="las la-times-circle"
                            />
                            <i
                              v-if="
                                passwordValidators(form_for_current_sector.password)
                                  .atLeastOneDigits
                              "
                              class="las la-check-circle"
                            />
                          </div>
                          au moins un chiffre
                        </li>
                        <li
                          :class="{
                            valid: passwordValidators(form_for_current_sector.password)
                              .atLeastOneSpecialChar,
                          }"
                        >
                          <div class="icons">
                            <i
                              v-if="
                                !passwordValidators(form_for_current_sector.password)
                                  .atLeastOneSpecialChar
                              "
                              class="las la-times-circle"
                            />
                            <i
                              v-if="
                                passwordValidators(form_for_current_sector.password)
                                  .atLeastOneSpecialChar
                              "
                              class="las la-check-circle"
                            />
                          </div>
                          au moins un caractère special (@#$%^&+=)
                        </li>
                        <li
                          :class="{
                            valid: passwordValidators(form_for_current_sector.password)
                              .minimumLength,
                          }"
                        >
                          <div class="icons">
                            <i
                              v-if="
                                !passwordValidators(form_for_current_sector.password).minimumLength
                              "
                              class="las la-times-circle"
                            />
                            <i
                              v-if="
                                passwordValidators(form_for_current_sector.password).minimumLength
                              "
                              class="las la-check-circle"
                            />
                          </div>
                          au moins 8 caractères
                        </li>
                      </ul>
                    </small>
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                md="12"
                lg="4"
              >
                <!-- confirm password -->
                <b-form-group
                  label-for="register-confirm-password"
                  :label="$t('register_operators.label_confirm_password')"
                >
                  <template v-slot:label>
                    <span>{{ $t("register_operators.label_confirm_password") }}
                      <RequiredStar />
                    </span>
                  </template>
                  <b-input-group
                    class="input-group-merge"
                    :class="
                      $v.form_for_current_sector.confirmPassword.$error ? 'is-invalid' : null
                    "
                  >
                    <b-form-input
                      id="register-confirm-password"
                      v-model="form_for_current_sector.confirmPassword"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="$v.form_for_current_sector.confirmPassword.$error ? false : null"
                      name="register-confirm-password"
                      placeholder="············"
                      @blur="$v.form_for_current_sector.confirmPassword.$touch"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="
                          passwordFieldType === 'password'
                            ? 'EyeIcon'
                            : 'EyeOffIcon'
                        "
                        class="cursor-pointer"
                        @click="togglePasswordInputType"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <div v-if="$v.form_for_current_sector.confirmPassword.$error">
                    <small
                      v-if="$v.form_for_current_sector.confirmPassword.isValid.$invalid"
                      class="text-danger"
                    >Les mots de passe ne sont pas identiques</small>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </tab-content>
      </form-wizard>
    </b-card>

    <!-- modal for notification -->
    <b-modal
      id="modal-info"
      ref="modal-info"
      hide-backdrop
      no-close-on-backdrop
      hide-footer
      content-class="shadow"
      title="Informations Importantes"
      hide-header-close
    >
      <b-card-text>
        <span>Alerte : Faites attention à ce que vous faites. Votre responsabilté est engagé</span>
      </b-card-text>
      <hr>
      <b-form-checkbox @change="hideModal()">
        J'accepte les termes et conditions
      </b-form-checkbox>
    </b-modal>
    <b-modal
      id="modal-register"
      ref="modal-register"
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="Aperçu des informations"
      centered
      size="xl"
    >
    <div v-if="!is_UEGT_sector_selected">
      <div class="bg-primary mt-2" >
      <b-row class="text-center py-1 px-1">
        <b-col
          cols="12"
          class="text-center text-white d-flex align-items-center justify-content-between"
        >
          <strong>INFORMATIONS SUR LE SECTEUR </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <b-row>
        <b-col cols="12" xl="6" class="mb-1 mt-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Secteur:&nbsp; </span>
            <strong>
              {{ form_for_current_sector.secteur && form_for_current_sector.secteur.title != null ? form_for_current_sector.secteur.title : '-' }}
            </strong>
          </span>
        </b-col>
      </b-row>
    </b-card-body>
      <div class="bg-primary mt-2" >
      <b-row class="text-center py-1 px-1">
        <b-col
          cols="12"
          class="text-center text-white d-flex align-items-center justify-content-between"
        >
          <strong>INFORMATIONS SUR LE RCCM </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <b-row>
        <b-col cols="12" xl="3" class="mb-1 mt-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> RCCM:&nbsp; </span>
            <strong>
              {{ form_for_current_sector.rccm != null ? form_for_current_sector.rccm : '-' }}
            </strong>
          </span>
        </b-col>
      </b-row>
    </b-card-body>
    <div class="bg-primary mt-2" >
      <b-row class="text-center py-1 px-1"  >
        <b-col
          cols="12"
          class="text-center text-white d-flex align-items-center justify-content-between"
        >
          <strong>INFORMATIONS SUR LE NPI </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding" >
      <b-row>
        <b-col cols="12" xl="3" class="mb-1 mt-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> NPI:&nbsp; </span>
            <strong>
              {{ form_for_current_sector.cpi_npi_representant_legal != null ? form_for_current_sector.cpi_npi_representant_legal : '-' }}
            </strong>
          </span>
        </b-col>
      </b-row>
    </b-card-body>
      <div class="bg-primary mt-2" v-if="!is_UEGT_sector_selected">
      <b-row class="text-center py-1 px-1"  >
        <b-col
          cols="12"
          class="text-center text-white d-flex align-items-center justify-content-between"
        >
          <strong>{{$t('demand_detail.info_establishment')}} </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <b-row>
        <b-col cols="12" xl="6" class="mb-1" >
          <span class="d-flex justify-content-start">
            <span class="my-auto" >{{ $t('register_operators.label_company_name') }} :&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.nom_ets != null
                  ? form_for_current_sector.nom_ets
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1" >
          <span class="d-flex justify-content-start">
            <span class="my-auto" >Adresse:&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.adresse_ets != null
                  ? form_for_current_sector.adresse_ets
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1" >
          <span class="d-flex justify-content-start">
            <span class="my-auto" >Numéro IFU(Facultatif):&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.ifu != null
                  ? form_for_current_sector.ifu
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1" >
          <span class="d-flex justify-content-start">
            <span class="my-auto" >Téléphone:&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.telephone_ets != null
                  ? form_for_current_sector.telephone_ets
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Email:&nbsp; </span>

            <strong class="my-auto">
              {{
                form_for_current_sector.email_ets != null ? form_for_current_sector.email_ets : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="3" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Site web:&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.web_site != null
                  ? form_for_current_sector.web_site
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Département:&nbsp; </span>
            <strong class="my-auto">
              {{
                form_for_current_sector.departement != null
                  ? form_for_current_sector.departement
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Commune:&nbsp; </span>
            <strong class="my-auto">
              {{
                form_for_current_sector.commune != null
                  ? form_for_current_sector.commune
                  : '-'
              }}
            </strong>
          </span>
        </b-col>

        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Arrondissement:&nbsp; </span>
            <strong class="my-auto">
              {{
                form_for_current_sector.arrondissement != null
                  ? form_for_current_sector.arrondissement
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Quartier:&nbsp; </span>
            <strong class="my-auto">
              {{
                form_for_current_sector.qtier != null
                  ? form_for_current_sector.qtier
                  : '-'
              }}
            </strong>
          </span>
        </b-col>

        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Pièces jointes:&nbsp; </span>
            <strong class="my-auto" v-for="piece in form_for_current_sector.pieces_jointes">
              {{
                form_for_current_sector.piece != null ? form_for_current_sector.piece : '-'
              }}
            </strong>
          </span>
        </b-col>
      </b-row>
      <hr class="hr-primary" />
      <div class="mb-1">
          <span > Localisation:&nbsp; </span>
          <MapsComponent
            v-if="form && form_for_current_sector.localization && form_for_current_sector.localization.lng && form_for_current_sector.localization.lat"
            map-id="map2"
            :positions="[{lat:form_for_current_sector.localization.lat,lng:form_for_current_sector.localization.lng}]"
          />
      </div>
    </b-card-body>
    
        <div class="bg-primary mt-2">
      <b-row class="text-center py-1 px-1">
        <b-col
          md="12"
          class="text-center text-white d-flex align-items-center justify-content-between"
        >
          <strong>INFORMATIONS SUR LE PROMOTEUR </strong>
        </b-col>
      </b-row>
    </div>
      <b-row class=" mt-1 ml-1">
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Nom du promoteur:&nbsp; </span>
            <strong class="my-auto">
              {{
                form_for_current_sector.nom_promoteur != null
                  ? form_for_current_sector.nom_promoteur
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col
          cols="12"
          xl="6"
          class="mb-1"
        >
          <span class="d-flex justify-content-start">
            <span class="my-auto" v-if="!is_UEGT_sector_selected"> Prénom(s) du promoteur:&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.prenoms_promoteur != null
                  ? form_for_current_sector.prenoms_promoteur
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Sexe:&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.sexe_promoteur != null
                  ? form_for_current_sector.sexe_promoteur
                  : '-'
              }}
            </strong>

          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span >
            <span class="my-auto">  </span>
            <span class="my-auto"> Nationalité du promoteur:&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.nationalite_promoteur != null
                  ? form_for_current_sector.nationalite_promoteur
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Téléphone:&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.telephone_promoteur != null
                  ? form_for_current_sector.telephone_promoteur
                  : '-'
              }}
            </strong>

          </span>
        </b-col>
      
      </b-row>
      <div class="bg-primary mt-2">
      <b-row class="text-center py-1 px-1">
        <b-col
          md="12"
          class="text-center text-white d-flex align-items-center justify-content-between"
        >
          <strong>INFORMATIONS DE CONNEXION </strong>
        </b-col>
      </b-row>
    </div>
      <b-row class=" mt-1 ml-1">

        <b-col
          cols="12"
          xl="6"
          class="mb-1"
        >
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Nom :&nbsp; </span>
            <strong class="my-auto">
              {{
                form_for_current_sector.nom_representant != null
                  ? form_for_current_sector.nom_representant
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto" v-if="!is_UEGT_sector_selected"> Prénom(s) :&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.prenoms_representant != null
                  ? form_for_current_sector.prenoms_representant
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Sexe:&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.sexe_representant != null
                  ? form_for_current_sector.sexe_representant
                  : '-'
              }}
            </strong>

          </span>
        </b-col>
                <b-col
          cols="12"
          xl="6"
          class="mb-1"
        >
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Téléphone:&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.telephone_representant != null
                  ? form_for_current_sector.telephone_representant
                  : '-'
              }}
            </strong>

          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Nationalité :&nbsp; </span>

            <strong class="my-auto">
              {{
                form_for_current_sector.nationalite_representant != null ? form_for_current_sector.nationalite_representant: '-'
              }}
            </strong>
          </span>
        </b-col>

        <hr class="hr-primary" />
          <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Email:&nbsp; </span>

            <strong class="my-auto">
              {{
                form_for_current_sector.email_ets != null ? form_for_current_sector.email_ets : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Mot de passe:&nbsp; </span>

            <strong class="my-auto">
              {{
                form_for_current_sector.password != null ? maskText(form_for_current_sector.password) : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Mot de passe confirmé:&nbsp; </span>

            <strong class="my-auto">
              {{
                form_for_current_sector.confirmPassword != null ? maskText(form_for_current_sector.confirmPassword) : '-'
              }}
            </strong>
          </span>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <div class="bg-primary mt-2" >
      <b-row class="text-center py-1 px-1">
        <b-col
          cols="12"
          class="text-center text-white d-flex align-items-center justify-content-between"
        >
          <strong>INFORMATIONS SUR LE SECTEUR </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <b-row>
        <b-col cols="12" xl="3" class="mb-1 mt-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Secteur:&nbsp; </span>
            <strong>
              {{ form_for_current_sector.secteur && form_for_current_sector.secteur.title != null ? form_for_current_sector.secteur.title : '-' }}
            </strong>
          </span>
        </b-col>
      </b-row>
    </b-card-body>
    <div class="bg-primary mt-2" >
      <b-row class="text-center py-1 px-1"  >
        <b-col
          cols="12"
          class="text-center text-white d-flex align-items-center justify-content-between"
        >
          <strong>INFORMATIONS SUR LE NPI </strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding" >
      <b-row>
        <b-col cols="12" xl="3" class="mb-1 mt-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> NPI:&nbsp; </span>
            <strong>
              {{ form_for_current_sector.cpi_npi_representant_legal != null ? form_for_current_sector.cpi_npi_representant_legal : '-' }}
            </strong>
          </span>
        </b-col>
      </b-row>
    </b-card-body>
    <div class="bg-primary mt-2">
      <b-row class="text-center py-1 px-1">
        <b-col
          md="12"
          class="text-center text-white d-flex align-items-center justify-content-between"
        >
          <strong >INFORMATIONS SUR LE GUIDE </strong>
        </b-col>
      </b-row>
    </div>
      <b-row class=" mt-1 ml-1">
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto" > Nom du guide:&nbsp; </span>

            <strong class="my-auto">
              {{
                form_for_current_sector.nom_representant != null
                  ? form_for_current_sector.nom_representant
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto" > Prénom(s) du guide:&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.prenoms_representant != null
                  ? form_for_current_sector.prenoms_representant
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col
          cols="12"
          xl="6"
          class="mb-1"
        >
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Sexe:&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.sexe_representant != null
                  ? form_for_current_sector.sexe_representant
                  : '-'
              }}
            </strong>

          </span>
        </b-col>
        <b-col
          cols="12"
          xl="6"
          class="mb-1"
        >
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Téléphone:&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.telephone_representant != null
                  ? form_for_current_sector.telephone_representant
                  : '-'
              }}
            </strong>

          </span>
        </b-col>
         <b-col cols="12" xl="6" class="mb-1">
          <span >
            <span class="my-auto" > Nationalité du guide:&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.nationalite_representant != null
                  ? form_for_current_sector.nationalite_representant
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Site web:&nbsp; </span>
            <strong>
              {{
                form_for_current_sector.web_site != null
                  ? form_for_current_sector.web_site
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Département:&nbsp; </span>
            <strong class="my-auto">
              {{
                form_for_current_sector.departement != null
                  ? form_for_current_sector.departement
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Commune:&nbsp; </span>
            <strong class="my-auto">
              {{
                form_for_current_sector.commune != null
                  ? form_for_current_sector.commune
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Arrondissement:&nbsp; </span>
            <strong class="my-auto">
              {{
                form_for_current_sector.arrondissement != null
                  ? form_for_current_sector.arrondissement
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Quartier:&nbsp; </span>
            <strong class="my-auto">
              {{
                form_for_current_sector.qtier != null
                  ? form_for_current_sector.qtier
                  : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col
          cols="12"
          xl="6"
          class="mb-1"
        >
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Email:&nbsp; </span>

            <strong class="my-auto">
              {{
                form_for_current_sector.email_representant != null ? form_for_current_sector.email_representant : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Mot de passe:&nbsp; </span>

            <strong class="my-auto">
              {{
                form_for_current_sector.password != null ? maskText(form_for_current_sector.password) : '-'
              }}
            </strong>
          </span>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Mot de passe confirmé:&nbsp; </span>

            <strong class="my-auto">
              {{
                form_for_current_sector.confirmPassword != null ? maskText(form_for_current_sector.confirmPassword) : '-'
              }}
            </strong>
          </span>
        </b-col>
        
        <b-col cols="12" xl="6" class="mb-1">
          <span class="d-flex justify-content-start">
            <span class="my-auto"> Pièces jointes:&nbsp; </span>

            <strong class="my-auto" v-for="piece in form_for_current_sector.pieces_jointes">
              {{
                form_for_current_sector.piece != null ? form_for_current_sector.piece : '-'
              }}
            </strong>
          </span>
        </b-col>
      </b-row>
       <hr class="hr-primary" />
              <div class="mb-1">
          <span > Localisation:&nbsp; </span>
          <MapsComponent
            v-if="form && form_for_current_sector.localization && form_for_current_sector.localization.lng && form_for_current_sector.localization.lat"
            map-id="map2"
            :positions="[{lat:form_for_current_sector.localization.lat,lng:form_for_current_sector.localization.lng}]"
          />
      </div>
    </div>
     <div class="d-flex flex-column justify-content-center mt-5">
      <button
        type="button"
        class="btn btn-warning"
        @click="submitForm()"
      >
        <span>
          <b-spinner
            v-if="isLoading"
            small
          />
          <!-- {{ $t("button.register") }} -->
          S'inscrire
        </span>
      </button>
     </div>

    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BModal,
  BFormRadioGroup,
  BFormRadio,
  VBTooltip,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import AppLogo from '@/components/AppLogo.vue'
import RecaptchaWidget from '@/components/RecaptchaWidget.vue'
// import localstorageService from "@/services/localstorage/localstorage.service";
import passwordValidators from '@/helpers/password-validators'
import DepartementCommuneArrondissementVillageForm from '@/components/DepartementCommuneArrondissementVillageForm.vue'
import RequiredStar from '@/components/RequiredStar.vue'
import MapsComponent from '@/components/MapsComponent.vue'
import utilsService from '@/services/utils/utils.service'
// store module and vuex utilities
import etablissementsStoreModule from '@/store/etablissements'
import secteursStoreModule from '@/store/secteurs'
import { VueTelInput } from 'vue-tel-input'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

import { $themeConfig } from '@themeConfig'
import { xml2json } from 'xml-js'

// import { useI18n } from "vue-i18n";
// const { t, locale } = useI18n();

// import PhoneNumberInput from "vue-phone-number-input";

export default {
  page() {
    return {
      title: () => this.title,
      meta: [{ name: 'description', content: $themeConfig.app.description }],
    }
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    // validations
    AppLogo,
    DepartementCommuneArrondissementVillageForm,
    vSelect,
    RecaptchaWidget,
    BSpinner,
    BRow,
    BCol,
    BModal,
    BFormRadioGroup,
    BFormRadio,
    VueTelInput,
    FormWizard,
    TabContent,
    RequiredStar,
    MapsComponent,
    // PhoneNumberInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStoreModule },
      { path: 'secteurs', module: secteursStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return {
      requiredStoreModules,
      passwordValidators,
      $v: useVuelidate({ $lazy: true }),
    }
  },
  data() {
    return {
      inputOptions: {
        showDialCode: true,
      },
      positions: [
        { lat: 51.505, lng: -0.09 },
        { lat: 47.457809, lng: -1.571045 },
        { lat: 47.739323, lng: -1.560059 },
        { lat: 47.886881, lng: -0.922852 },
        { lat: 48.231991, lng: -0.769043 },
      ],
      bindProps: {
        mode: 'international',
        defaultCountry: 'BJ',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: 'Enter a phone number',
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        preferredCountries: ['BJ'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      title: 'Inscription des utilisateurs',
      piecesJointesList: [
        { label: 'Photo 1', value: null, fileContent: null },
        { label: 'Photo 2', value: null, fileContent: null },
        { label: 'Photo 3', value: null, fileContent: null },
      ],
      triggerDepartementFormValidations: false,
      selected_sector: null,
      loading_form_content: false,
      form: { },
      form_for_current_sector: { },
      labels_for_current_sector: { },
      active_sections: {
        rccm: { active: true, label: 'RCCM de l\'établissement' },
        npi: { active: true, label: 'NPI du représentant légal' },
        establishment: { active: true, label: 'L\'entreprise' },
        promotor: { active: true, label: 'Le promoteur' },
        connexion_informations: { active: true, label: 'Information de connexion' },
      },

      isNextButtonDisabled: false, // Variable pour contrôler l'état du bouton
      rccm_string_valid: false,
      have_check_rccm: false,
      rccm_value_valid: false,
      isLoading_check_rccm: false,
      isLoading_check_npi: false,
      have_check_npi: false,
      npi_value_valid: false,
      options_secteurs: [],
      options_type_secteur_hebergement: [''],
      options_type_guide_tourisme: ['Guide culturel', 'Guide des milieux naturels'],
      options_categorie_guide_tourisme: [
        'Guide local', 'Guide national ou accompagnateur', 'Guide communautaire',
      ],
      passwordFieldType: 'password',

      recaptchaResponse: null,
      showResume: false,
      isLoading: false,
      passwordFieldIsFocused: false,
      isRegisteredSuccessfully: false,
      matching_data: {
        npi: {
          lastname: null,
          firstname: null,
        },
        rccm: {
          lastname: "N'TIA",
          firstname: "N'KOUEI KUGNIMU THEODULE",
          // N'KOUEI KUGNIMU THEODULE
        },
      },
      matching_result: {
        lastname: null,
        firstname: null,
        pass: true,
      },
    }
  },
  computed: {
    dynamicValidations() {
      if (!this.selected_sector) {
        return {
        // selected_sector: { required },
        }
      }
      // console.log('selected_sector::: 🟠🟢🟠🟢 ', this.selected_sector)
      // console.log('selected_sector.code::: 🟠🟢🟠🟢 ', this.selected_sector.code)

      switch (this.selected_sector.code) {
        case 'UEH':
          return {
            form_for_current_sector: {
              secteur: { required },
              rccm: { required },
              cpi_npi_representant_legal: {
                isValid(value) {
                  if (value && value.toString().length === 10) return true
                  if (!value) return false
                  return false
                },
              },
              ifu: {
                isValid(value) {
                  if (value && value.toString().length === 13) return true
                  if (!value) return true
                  return false
                },
              },
              nom_ets: { required },
              adresse_ets: { required },
              nbre_unites_logements: { required },
              telephone_ets: { required },
              email_ets: { required, email },

              departement: { required },
              commune: { required },
              arrondissement: { required },
              qtier: { required },

              nom_representant: { required },
              prenoms_representant: { required },
              sexe_representant: { required },
              nationalite_representant: { required },
              telephone_representant: { required },

              nom_promoteur: { required },
              prenoms_promoteur: { required },
              telephone_promoteur: { required },
              sexe_promoteur: { required },
              nationalite_promoteur: { required },
              email_promoteur: { required, email },

              password: {
                required,
                isValid(value) {
                  return this.passwordValidators(value).isValid
                },
              },
              confirmPassword: {
                isValid: val => val && val === this.form_for_current_sector.password,
              },
            },
          }
        case 'UEAV':
          return {
            form_for_current_sector: {
              secteur: { required },
              rccm: { required },
              cpi_npi_representant_legal: {
                isValid(value) {
                  if (value && value.toString().length === 10) return true
                  if (!value) return false
                  return false
                },
              },
              ifu: {
                isValid(value) {
                  if (value && value.toString().length === 13) return true
                  if (!value) return true
                  return false
                },
              },
              nom_ets: { required },
              adresse_ets: { required },
              telephone_ets: { required },
              email_ets: { required, email },

              departement: { required },
              commune: { required },
              arrondissement: { required },
              qtier: { required },

              nom_representant: { required },
              prenoms_representant: { required },
              sexe_representant: { required },
              nationalite_representant: { required },
              telephone_representant: { required },

              nom_promoteur: { required },
              prenoms_promoteur: { required },
              telephone_promoteur: { required },
              sexe_promoteur: { required },
              nationalite_promoteur: { required },
              email_promoteur: { required, email },

              password: {
                required,
                isValid(value) {
                  return this.passwordValidators(value).isValid
                },
              },
              confirmPassword: {
                isValid: val => val && val === this.form_for_current_sector.password,
              },
            },
          }
        case 'UEGT':
          return {
            form_for_current_sector: {
              secteur: { required },
              cpi_npi_representant_legal: {
                isValid(value) {
                  if (value && value.toString().length === 10) return true
                  if (!value) return false
                  return false
                },
              },
              nom_representant: { required },
              prenoms_representant: { required },
              sexe_representant: { required },
              telephone_representant: { required },
              email_representant: { required, email },
              nationalite_representant: { required },

              password: {
                required,
                isValid(value) {
                  return this.passwordValidators(value).isValid
                },
              },
              confirmPassword: {
                isValid: val => val && val === this.form_for_current_sector.password,
              },

              departement: { required },
              commune: { required },
              arrondissement: { required },
              qtier: { required },
            },
          }
        case 'UER':
          return {
            form_for_current_sector: {
              secteur: { required },
              rccm: { required },
              cpi_npi_representant_legal: {
                isValid(value) {
                  if (value && value.toString().length === 10) return true
                  if (!value) return false
                  return false
                },
              },
              ifu: {
                isValid(value) {
                  if (value && value.toString().length === 13) return true
                  if (!value) return true
                  return false
                },
              },
              nom_ets: { required },
              adresse_ets: { required },
              telephone_ets: { required },
              email_ets: { required, email },
              nbre_couverts: { required },

              departement: { required },
              commune: { required },
              arrondissement: { required },
              qtier: { required },

              nom_representant: { required },
              prenoms_representant: { required },
              sexe_representant: { required },
              nationalite_representant: { required },
              telephone_representant: { required },

              nom_promoteur: { required },
              prenoms_promoteur: { required },
              telephone_promoteur: { required },
              sexe_promoteur: { required },
              nationalite_promoteur: { required },
              email_promoteur: { required, email },

              password: {
                required,
                isValid(value) {
                  return this.passwordValidators(value).isValid
                },
              },
              confirmPassword: {
                isValid: val => val && val === this.form_for_current_sector.password,
              },
            },
          }
        default:
          console.log('default case')
          break
      }
    },
    is_UEGT_sector_selected() {
      return this.selected_sector && this.selected_sector.code === 'UEGT'
    },
    is_UER_sector_selected() {
      return this.selected_sector && this.selected_sector.code === 'UER'
    },
    is_UEH_sector_selected() {
      return this.selected_sector && this.selected_sector.code === 'UEH'
    },
    is_UEAV_sector_selected() {
      return this.selected_sector && this.selected_sector.code === 'UEAV'
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    customTranslations() {
      return {
        countrySelectorLabel: this.$t('input_number.countrySelectorLabel'),
        countrySelectorError: this.$t('input_number.countrySelectorError'),
        phoneNumberLabel: this.$t('input_number.phoneNumberLabel'),
        example: this.$t('input_number.example'),
      }
    },
    sexe_list() {
      return utilsService.getSex() || []
    },
    pays() {
      return utilsService.getListCountries() || []
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: false,
      handler(val, old) {
        this.getListSecteursEtablissement()
        this.getListTypeHebergement()
      },
    },
    passwordFieldIsFocused: {
      immediate: false,
      deep: false,
      handler(val, old) {
        this.$v.form_for_current_sector.password.$validate()
      },
    },
    'form_for_current_sector.rccm': {
      immediate: false,
      deep: true,
      handler(val, old) {
        this.rccm_string_valid = false
        const rccmRegex = /^[A-Z0-9]{2}[-/][A-Z0-9]{3}[-/][A-Z0-9\s]+$/
        if (rccmRegex.test(val)) {
          // La chaîne de caractères respecte le format
          this.rccm_string_valid = true
        } else {
          // La chaîne de caractères ne respecte pas le format
          this.rccm_string_valid = false
        }
      },
    },
    selected_sector: {
      immediate: false,
      deep: false,
      handler(val, old) {
        // console.log('selected_sector val::: ⭕️🟠🟢', val)
        const sector = val
        if (!sector) return

        switch (sector.code) {
          case 'UEH':
            this.form_for_current_sector = {
              secteur: sector,
              rccm: '',
              nom_ets: '',
              // type_etablissement_id: null,
              // sous_type_ets: { radio: '', input: '' },
              localization: { lng: '', lat: '', },
              adresse_ets: '',
              telephone_ets: '',
              email_ets: '',
              web_site: '',
              nom_promoteur: null,
              prenoms_promoteur: null,
              sexe_promoteur: null,
              nationalite_promoteur: '',
              telephone_promoteur: '',
              nbre_unites_logements: 0,
              // code_npi: "",
              email_promoteur: '',
              cpi_npi_representant_legal: null, // 251859464

              nom_representant: '',
              prenoms_representant: '',
              sexe_representant: '',
              nationalite_representant: '',
              telephone_representant: '',

              departement: '',
              commune: '',
              arrondissement: '',
              qtier: '',
              pieces_jointes: [],
              ifu: null,
              password: '',
              confirmPassword: '',
            }
            this.active_sections = {
              rccm: { active: true, label: 'RCCM de l\'établissement' },
              npi: { active: true, label: 'NPI du représentant légal' },
              establishment: { active: true, label: 'L\'établissement d\'hébergement' },
              promotor: { active: true, label: 'Le promoteur' },
              connexion_informations: { active: true, label: 'Information de connexion' },
            }
            this.labels_for_current_sector = {
              numero_agrement: "Numéro d'autorisation d'exploitation (Facultatif)",
              cpi_npi_representant_legal: 'Numéro NPI du représentant légal',
            }
            break
          case 'UEAV':
            this.form_for_current_sector = {
              secteur: sector,
              rccm: '',
              nom_ets: '',
              adresse_ets: '',
              telephone_ets: '',
              email_ets: '',
              web_site: '',
              nom_promoteur: null,
              prenoms_promoteur: null,
              sexe_promoteur: null,
              nationalite_promoteur: '',
              telephone_promoteur: '',
              // code_npi: "",
              email_promoteur: '',
              cpi_npi_representant_legal: null, // 251859464

              departement: '',
              commune: '',
              arrondissement: '',
              qtier: '',
              pieces_jointes: [],
              localization: {
                lng: '',
                lat: '',
              },
              ifu: null,
              password: '',
              confirmPassword: '',
            }
            this.active_sections = {
              rccm: { active: true, label: 'RCCM de l\'établissement' },
              npi: { active: true, label: 'NPI du représentant légal' },
              establishment: { active: true, label: 'L\'agence de voyage' },
              promotor: { active: true, label: 'Le promoteur' },
              connexion_informations: { active: true, label: 'Information de connexion' },
            }
            this.labels_for_current_sector = {
              numero_agrement: "Numéro de licence d'exlploitation (Facultatif)",
              cpi_npi_representant_legal: 'Numéro NPI du représentant légal',
            }
            break
          case 'UEGT':
            this.form_for_current_sector = {
              secteur: sector,
              cpi_npi_representant_legal: null,
              web_site: '',

              nom_representant: null,
              prenoms_representant: null,
              sexe_representant: null,
              telephone_representant: '',
              nationalite_representant: '',
              email_representant: '',

              departement: '',
              commune: '',
              arrondissement: '',
              qtier: '',
              pieces_jointes: [],
              localization: { lng: '', lat: '', },
              password: '',
              confirmPassword: '',
            }
            this.active_sections = {
              rccm: { active: false, label: 'RCCM de l\'établissement' },
              npi: { active: true, label: 'NPI du guide' },
              establishment: { active: true, label: 'Le guide de tourisme' },
              promotor: { active: false, label: 'Le promoteur' },
              connexion_informations: { active: false, label: 'Information de connexion' },
            }
            this.labels_for_current_sector = {
              numero_agrement: 'Numéro de carte professionnelle (Facultatif)',
              cpi_npi_representant_legal: 'Numéro NPI du guide',
              
            }
            break
          case 'UER':
            this.form_for_current_sector = {
              secteur: sector,
              rccm: 'RB/COT/23 A 94737',
              nom_ets: '',
              adresse_ets: '',
              telephone_ets: '',
              email_ets: '',
              web_site: '',
              nom_promoteur: null,
              prenoms_promoteur: null,
              sexe_promoteur: null,
              nationalite_promoteur: '',
              telephone_promoteur: '',
              email_promoteur: '',
              nbre_couverts: 0,
              cpi_npi_representant_legal: null, // 251859464
              localization: {
                lng: '',
                lat: '',
              },
              departement: '',
              commune: '',
              arrondissement: '',
              qtier: '',
              pieces_jointes: [],
              ifu: null,
              password: '',
              confirmPassword: '',
            }
            this.active_sections = {
              rccm: { active: true, label: 'RCCM de l\'établissement' },
              npi: { active: true, label: 'NPI du représentant légal' },
              establishment: { active: true, label: 'L\'établissement de restauration' },
              promotor: { active: true, label: 'Le promoteur' },
              connexion_informations: { active: true, label: 'Information de connexion' },
            }
            this.labels_for_current_sector = {
              numero_agrement: 'Numéro Agrément / Licence / Autorisation (Facultatif)',
              cpi_npi_representant_legal: 'Numéro NPI du représentant légal',
            }
            break
          default:
            console.log('default case')
            break
        }
        // console.log('this.form_for_current_sector::: ', this.form_for_current_sector)
        this.loading_form_content = true
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },
  validations() {
    return this.dynamicValidations
  },
  mounted() {
  
  },
  methods: {
    ...mapActions('secteurs', {
      fetchSecteurs: 'fetchSecteurs',
    }),
    ...mapActions('etablissements', {
      action_createEtablissement: 'createEtablissement',
      action_getListTypesEtablissement: 'getListTypesEtablissement',
      action_searchTypeEtablissement: 'searchTypeEtablissement',
    }),
    ...mapActions('imagePuzzleSliderCaptcha', { showSliderCaptcha: 'show' }),
    // ---------------------------------------------------------------------------
    hideModal() {
      this.$refs['modal-info'].hide()
      this.showRegisterModal()
    },
    onTypeEtsInputDirty() {
      this.$v.form_for_current_sector.secteur.$touch()
      if (this.$v.form_for_current_sector.sous_type_ets) {
        this.$v.form_for_current_sector.sous_type_ets.radio.$touch()
        this.$v.form_for_current_sector.sous_type_ets.input && this.$v.form_for_current_sector.sous_type_ets.input.$touch()
      }
    },
    getListSecteursEtablissement() {
      this.action_getListTypesEtablissement()
        .then(res => {
          this.options_secteurs = res.data.data || []
        })
        .catch(err => {})
    },
    getListTypeHebergement() {
      this.action_searchTypeEtablissement()
        .then(res => {
          this.options_type_secteur_hebergement = res.data.data || []
        })
        .catch(err => {})
    },
    onRecaptchaChange(recaptchaEvent) {
      if (recaptchaEvent && recaptchaEvent.type === 'success') {
        this.recaptchaResponse = recaptchaEvent
      } else {
        this.recaptchaResponse = null
      }
    },
    onDepartementFormChange(evt) {
      this.form_for_current_sector.departement = evt.departement
      this.form_for_current_sector.commune = evt.commune
      this.form_for_current_sector.arrondissement = evt.arrondissement
      this.form_for_current_sector.qtier = evt.qtier
    },
    isValidFile(file) {
      if (file && file.size && file.size > 1000000) {
        return false
      }
      return true
    },
     maskText(text) {
  return text.replace(/./g, '*');
    },
    change_rccm() {
      if(!this.rccm_string_valid) return
      if(this.isLoading_check_rccm) return
      this.isLoading_check_rccm = true

      this.$store
        .dispatch('utils/checkRCCM', {
          numeroRccm: this.form_for_current_sector.rccm,
        })
        .then(response => {
          this.have_check_rccm = true
          this.isLoading_check_rccm = false
          if (response.data && response.data.length === 1) {
            // console.log("utils/checkRCCM response::: 🟢🟢🟢", response);
            const data = response.data[0]
            if (data.numerorccm === this.form_for_current_sector.rccm) {
              this.rccm_value_valid = true
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Numéro RCCM valide',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            }
          } else {
            this.rccm_value_valid = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Contrôl échoué ou numéro RCCM invalide. Veuillez vous assurer de renseigner le bon numéro. ',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          console.log('utils/checkRCCM error::: ⭕️', error)
          this.isLoading_check_rccm = false
          this.rccm_value_valid = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Contrôl échoué ou numéro RCCM invalide. Veuillez vous assurer de renseigner le bon numéro. ',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    step_rccm() {
      return new Promise(async (resolve, reject) => {
        // console.log('this.rccm_value_valid::: ❌❌❌', this.rccm_value_valid);
        if(!this.rccm_value_valid){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Contrôle échoué ou numéro RCCM invalide. Veuillez vous assurer de renseigner le bon numéro. ',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          reject()
          return
        }
        const isFormCorrect = await this.$v.$validate()
        // if (true) {
        if (
          !this.$v.form_for_current_sector.rccm.$error &&
          this.rccm_value_valid &&
          this.rccm_string_valid &&
          !this.isLoading_check_rccm
        ){
          resolve(true)
        } else {
          if(!this.$v.form_for_current_sector.rccm.$error) this.alert_validate_inputs()
        }
        reject()
      })
    },
    change_npi_representant_legal() {
      if(this.isLoading_check_npi) return
      this.isLoading_check_npi = true
      this.$store
        .dispatch('utils/checkNPI', {
          numeroIfu: this.form_for_current_sector.cpi_npi_representant_legal,
        })
        .then(response => {
          this.have_check_npi = false
          this.isLoading_check_npi = false
          let json = xml2json(response.data, { spaces: 2, compact: true })
          json = JSON.parse(json)
          const final_object = json['soapenv:Envelope']['soapenv:Body'][
            'ns:CITIZEN_BY_NPI_LTEResponse'
          ]
          // console.log('utils/checkNPI response::: ⭕️⭕️⭕️', final_object)
          
          if (final_object && final_object.LAST_NAME) {
            this.npi_value_valid = true
            this.isLoading_check_npi = false
            if (final_object.LAST_NAME._text) {
              this.form_for_current_sector.nom_representant = final_object.LAST_NAME._text
              // this.matching_data.npi.lastname = this.form_for_current_sector.nom_representant
            }
            if (final_object.FIRST_NAME._text) {
              this.form_for_current_sector.prenoms_representant = final_object.FIRST_NAME._text
              // this.matching_data.npi.firstname = this.form_for_current_sector.prenoms_representant
            }
            if (final_object.PHONE._text) {
              this.form_for_current_sector.telephone_representant = final_object.PHONE._text
            }
            if (final_object.SEX._text === 'M') {
              this.form_for_current_sector.sexe_representant = 'Masculin'
            }
            if (final_object.SEX._text === 'F') {
              this.form_for_current_sector.sexe_representant = 'Féminin'
            }
            if (final_object.EMAIL._text) {
              this.form_for_current_sector.email_promoteur = final_object.EMAIL._text
            }

            if(this.is_UEGT_sector_selected) {
              this.matching_data.pass = false
              return
            }
            // contrôle matching RCCM and NPI
            // const matchingPercentageLastname = this.getMatchingPercentage(
            //   this.matching_data.rccm.lastname,
            //   this.matching_data.npi.lastname,
            // )
            // this.matching_result.lastname = matchingPercentageLastname
             if (final_object) {
            // // console.log("utils/checkRCCM response::: 🟢🟢🟢", response);
             if (final_object.NPI._text === this.form_for_current_sector.cpi_npi_representant_legal) {
               this.npi_value_valid = true
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-right',
                 props: {
                   title: 'Numéro NPI valide',
                   icon: 'CheckIcon',
                   variant: 'success',
                 },
               })
             }
             }
          } else {
            this.npi_value_valid = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Contrôl échoué ou numéro NPI invalide. Veuillez vous assurer de renseigner le bon numéro. ',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
            // if (
            //   matchingPercentageLastname.pourcent !== 100
            //   || (matchingPercentageLastname.pourcent === 100
            //   && matchingPercentageLastname.same_length !== 0)
            // ) {
            //   this.$toast({
            //     component: ToastificationContent,
            //     props: {
            //       title:
            //         'Les données du RCCM et du NPI ne sont pas en accord. Veuillez vérifier les valeurs saisies.',
            //       icon: 'AlertTriangleIcon',
            //       variant: 'danger',
            //     },
            //   })
            //   this.matching_data.pass = false
            // } else {
            //   // contrôle matching firstname
            //   const matchingPercentageFirstname = this.getMatchingPercentage(
            //     this.matching_data.rccm.firstname,
            //     this.matching_data.npi.firstname,
            //   )
            //   this.matching_result.firstname = matchingPercentageFirstname
            //   if (!matchingPercentageFirstname.result) {
            //     this.$toast({
            //       component: ToastificationContent,
            //       props: {
            //         title:
            //           '🩸🩸Les données du RCCM et du NPI ne sont pas en accord. Veuillez vérifier les valeurs saisies.',
            //         icon: 'AlertTriangleIcon',
            //         variant: 'danger',
            //       },
            //     })
            //     this.matching_data.pass = false
            //   }
            // }
            // console.log("this.matching_result::: 🟢", this.matching_result);
        })
        .catch(error => {
          this.isLoading_check_npi = false
          this.npi_value_valid = false
          console.log("utils/checkNPI error::: ⭕️", error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Contrôl échoué ou numéro RCCM invalide. Veuillez vous assurer de renseigner le bon numéro. ',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    change_npi_code_promoteur() {
      // console.log("check code_npi::: ");
      // this.npi_ok = true;
      // this.$store
      //   .dispatch("utils/checkNPI_code", {
      //     numeroIfu: this.form_for_current_sector.code_npi,
      //   })
      //   .then((response) => {
      //     console.log("response::: ", response);
      //   })
      //   .catch((error) => {
      //     console.log("error::: ⭕️", error);
      //     this.$toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: error.message || "Process error",
      //         icon: "AlertTriangleIcon",
      //         variant: "danger",
      //       },
      //     });
      //   });
    },
    step_npi() {
      return new Promise(async (resolve, reject) => {

        if(!this.npi_value_valid){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Contrôle échoué ou numéro NPI invalide. Veuillez vous assurer de renseigner le bon numéro. ',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          reject()
          return
        }

        const isFormCorrect = await this.$v.$validate()
        if (!this.matching_result.pass) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                'Les données du RCCM et du NPI ne sont pas en accord. Veuillez vérifier les valeurs saisies.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          reject()
        }
        // if (true) {
           if (
             !this.$v.form_for_current_sector.cpi_npi_representant_legal.$error &&
             this.npi_value_valid &&
             !this.isLoading_check_npi
           ) {
          resolve(true)
        } else {
          if(!this.$v.form_for_current_sector.cpi_npi_representant_legal.$error) this.alert_validate_inputs()
        }
        reject()
      })
    },
    step_establishment() {
      return new Promise(async (resolve, reject) => {
        const isFormCorrect = await this.$v.$validate()
        // if (true) {
        if (this.is_UEGT_sector_selected) {
          if (true) {
            resolve(true)
          } else {
            this.alert_validate_inputs()
          }
        } else if (
          !this.$v.form_for_current_sector.nom_ets.$error
              && !this.$v.form_for_current_sector.adresse_ets.$error
              && !this.$v.form_for_current_sector.telephone_ets.$error
              && !this.$v.form_for_current_sector.email_ets.$error
              && !this.$v.form_for_current_sector.departement.$error
              && !this.$v.form_for_current_sector.commune.$error
              && !this.$v.form_for_current_sector.arrondissement.$error
              && !this.$v.form_for_current_sector.qtier.$error
        ) {
          resolve(true)
        } else {
          this.alert_validate_inputs()
        }
        reject()
      })
    },
    step_promotor() {
      return new Promise(async (resolve, reject) => {
        const isFormCorrect = await this.$v.$validate()
        // if (true) {
        if (
          !this.$v.form_for_current_sector.nom_promoteur.$error
          && !this.$v.form_for_current_sector.prenoms_promoteur.$error
          && !this.$v.form_for_current_sector.sexe_promoteur.$error
          && !this.$v.form_for_current_sector.nationalite_promoteur.$error
          && !this.$v.form_for_current_sector.telephone_promoteur.$error
          && !this.$v.form_for_current_sector.email_promoteur.$error
        ) {
          resolve(true)
        } else {
          this.alert_validate_inputs()
        }
        reject()
      })
    },
    // step_promotor() {
    //   return new Promise(async (resolve, reject) => {
    //     const isFormCorrect = await this.$v.$validate()
    //     if (
    //       !this.$v.form_for_current_sector.nom_representant.$error
    //       && !this.$v.form_for_current_sector.prenoms_representant.$error
    //       && !this.$v.form_for_current_sector.sexe_representant.$error
    //       && !this.$v.form_for_current_sector.nationalite_representant.$error
    //     ) {
    //       resolve(true)
    //     } else {
    //       this.alert_validate_inputs()
    //     }
    //     reject()
    //   })
    // },
    alert_validate_inputs() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Veuillez valider tous les champs du formulaire',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
    position_selected(data) {
      // console.log("position_selected data::: ⭕️", data);
      this.form_for_current_sector.localization = {
        lng: data.lng,
        lat: data.lat,
      }
    },
    handleFileChange(event, index) {
      const file = event.target.files[0]
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader()
        reader.onload = () => {
          // Mettez à jour fileContent avec l'URL de l'image
          this.piecesJointesList[index].fileContent = reader.result
        }
        reader.readAsDataURL(file) // Lecture du contenu de l'image en tant que données URL
      }
    },
    async showRecap() {
      // control form
      const isFormCorrect = await this.$v.$validate()
      if (!isFormCorrect) {
        this.triggerDepartementFormValidations = true
        this.alert_validate_inputs()
        return
      }

      // show recap modal
      this.$refs['modal-info'].show()

    },
    async submitForm() {
      this.isLoading = true
      if (!this.isLoading) return
      // start treatment

      const files = this.piecesJointesList.map(e => e.value).filter(e => !!e)
      const allFilesAreValid = files.map(file => this.isValidFile(file))

      for (let index = 0; index < allFilesAreValid.length; index++) {
        const element = allFilesAreValid[index]
        if (element === false) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title:
                'Vos fichiers ne sont pas au bon format. Veuillez renseigner des fichiers de 10Mo maximum, au format PDF',
              icon: 'CheckIcon',
              variant: 'danger',
            },
          })
          this.isLoading = false
          return
        }
      }
      // console.log("this.piecesJointesList::: ❌", this.piecesJointesList);
      // console.log("files::: ❌", files);

      this.triggerDepartementFormValidations = false
      this.isLoading = true

      // const sliderCaptchaResponse = await this.showSliderCaptcha()
      // const { isSolved } = sliderCaptchaResponse || {}
      // if (!isSolved) {
      //   this.isLoading = false
      //   return
      // }

      const isNotNull = val => val && val.trim() !== ''
      const getValueFromInput = (inputField, fieldType) => {
        let result = ''
        if (fieldType === 'radio') {
          result = inputField.radio && inputField.radio.requireManualInput
            ? inputField.input
            : inputField.radio?.value
        } else {
          result = isNotNull(inputField.input)
            ? inputField.input
            : inputField.value
        }
        return result
      }

      const formData = new FormData()

      switch (this.selected_sector.code) {
        case 'UEH':
          formData.append('nom_etablissement', this.form_for_current_sector.nom_ets)
          formData.append('npicni_promoteur', this.form_for_current_sector.cpi_npi_representant_legal)
          formData.append('secteur_id', this.form_for_current_sector.secteur.id)
          // if (this.form_for_current_sector.type_etablissement_id) {
          //   formData.append(
          //     'type_etablissement_id',
          //     this.form_for_current_sector.type_etablissement_id,
          //   )
          // }
          formData.append('nbre_unites_logements', this.form_for_current_sector.nbre_unites_logements)
          formData.append('adresse_etablissement', this.form_for_current_sector.adresse_ets)
          formData.append('telephone_etablissement', this.form_for_current_sector.telephone_ets)
          formData.append('email', this.form_for_current_sector.email_ets)
          formData.append('web_site', this.form_for_current_sector.web_site)
          formData.append('nom_representant', this.form_for_current_sector.nom_representant)
          formData.append('prenoms_representant', this.form_for_current_sector.prenoms_representant)
          formData.append('sexe_representant', this.form_for_current_sector.sexe_representant)
          formData.append('nationalite_representant', this.form_for_current_sector.nationalite_representant)
          formData.append('telephone_representant', this.form_for_current_sector.telephone_representant)
          formData.append('nom_promoteur', this.form_for_current_sector.nom_promoteur)
          formData.append('prenoms_promoteur', this.form_for_current_sector.prenoms_promoteur)
          formData.append('telephone_promoteur', this.form_for_current_sector.telephone_promoteur)
          formData.append('email_promoteur', this.form_for_current_sector.email_promoteur)
          formData.append('sexe_promoteur', this.form_for_current_sector.sexe_promoteur)
          formData.append('rccm', this.form_for_current_sector.rccm)
          formData.append('ifu', this.form_for_current_sector.ifu)
          formData.append('departement', this.form_for_current_sector.departement)
          formData.append('commune', this.form_for_current_sector.commune)
          formData.append('arrondissement', this.form_for_current_sector.arrondissement)
          formData.append('quartier', this.form_for_current_sector.qtier)
          formData.append('localization.lat', this.form_for_current_sector.localization.lat)
          formData.append('localization.lng', this.form_for_current_sector.localization.lng)
          break

        case 'UEAV':
          formData.append('nom_etablissement', this.form_for_current_sector.nom_ets)
          formData.append('npicni_promoteur', this.form_for_current_sector.cpi_npi_representant_legal)
          formData.append('secteur_id', this.form_for_current_sector.secteur.id)
          formData.append('adresse_etablissement', this.form_for_current_sector.adresse_ets)
          formData.append('telephone_etablissement', this.form_for_current_sector.telephone_ets)
          formData.append('email', this.form_for_current_sector.email_ets)
          formData.append('web_site', this.form_for_current_sector.web_site)
          formData.append('nom_representant', this.form_for_current_sector.nom_representant)
          formData.append('prenoms_representant', this.form_for_current_sector.prenoms_representant)
          formData.append('sexe_representant', this.form_for_current_sector.sexe_representant)
          formData.append('nationalite_representant', this.form_for_current_sector.nationalite_representant)
          formData.append('telephone_representant', this.form_for_current_sector.telephone_representant)
          formData.append('nom_promoteur', this.form_for_current_sector.nom_promoteur)
          formData.append('prenoms_promoteur', this.form_for_current_sector.prenoms_promoteur)
          formData.append('telephone_promoteur', this.form_for_current_sector.telephone_promoteur)
          formData.append('email_promoteur', this.form_for_current_sector.email_promoteur)
          formData.append('sexe_promoteur', this.form_for_current_sector.sexe_promoteur)
          formData.append('rccm', this.form_for_current_sector.rccm)
          formData.append('ifu', this.form_for_current_sector.ifu)
          formData.append('departement', this.form_for_current_sector.departement)
          formData.append('commune', this.form_for_current_sector.commune)
          formData.append('arrondissement', this.form_for_current_sector.arrondissement)
          formData.append('quartier', this.form_for_current_sector.qtier)
          formData.append('localization.lat', this.form_for_current_sector.localization.lat)
          formData.append('localization.lng', this.form_for_current_sector.localization.lng)
          break

        case 'UEGT':
          formData.append('secteur_id', this.form_for_current_sector.secteur.id)
          formData.append('npicni_promoteur', this.form_for_current_sector.cpi_npi_representant_legal)
          formData.append('web_site', this.form_for_current_sector.web_site)

          formData.append('nom_representant', this.form_for_current_sector.nom_representant)
          formData.append('prenoms_representant', this.form_for_current_sector.prenoms_representant)
          formData.append('sexe_representant', this.form_for_current_sector.sexe_representant)
          formData.append('telephone_representant', this.form_for_current_sector.telephone_representant)
          formData.append('nationalite_representant', this.form_for_current_sector.nationalite_representant)
          formData.append('email', this.form_for_current_sector.email_representant)
          
          formData.append('departement', this.form_for_current_sector.departement)
          formData.append('commune', this.form_for_current_sector.commune)
          formData.append('arrondissement', this.form_for_current_sector.arrondissement)
          formData.append('quartier', this.form_for_current_sector.qtier)

          formData.append('password', this.form_for_current_sector.password)
          formData.append('password_confirmation', this.form_for_current_sector.password)
          break

        case 'UER':
          formData.append('nom_etablissement', this.form_for_current_sector.nom_ets)
          formData.append('npicni_promoteur', this.form_for_current_sector.cpi_npi_representant_legal)
          formData.append('secteur_id', this.form_for_current_sector.secteur.id)
          // if (this.form_for_current_sector.type_etablissement_id) {
          //   formData.append(
          //     'type_etablissement_id',
          //     this.form_for_current_sector.type_etablissement_id,
          //   )
          // }
          formData.append('nbre_couverts', this.form_for_current_sector.nbre_couverts)
          formData.append('adresse_etablissement', this.form_for_current_sector.adresse_ets)
          formData.append('telephone_etablissement', this.form_for_current_sector.telephone_ets)
          formData.append('email', this.form_for_current_sector.email_ets)
          formData.append('web_site', this.form_for_current_sector.web_site)
          formData.append('nom_representant', this.form_for_current_sector.nom_representant)
          formData.append('prenoms_representant', this.form_for_current_sector.prenoms_representant)
          formData.append('sexe_representant', this.form_for_current_sector.sexe_representant)
          formData.append('nationalite_representant', this.form_for_current_sector.nationalite_representant)
          formData.append('telephone_representant', this.form_for_current_sector.telephone_representant)
          formData.append('nom_promoteur', this.form_for_current_sector.nom_promoteur)
          formData.append('prenoms_promoteur', this.form_for_current_sector.prenoms_promoteur)
          formData.append('telephone_promoteur', this.form_for_current_sector.telephone_promoteur)
          formData.append('email_promoteur', this.form_for_current_sector.email_promoteur)
          formData.append('sexe_promoteur', this.form_for_current_sector.sexe_promoteur)
          formData.append('rccm', this.form_for_current_sector.rccm)
          formData.append('ifu', this.form_for_current_sector.ifu)
          formData.append('departement', this.form_for_current_sector.departement)
          formData.append('commune', this.form_for_current_sector.commune)
          formData.append('arrondissement', this.form_for_current_sector.arrondissement)
          formData.append('quartier', this.form_for_current_sector.qtier)
          formData.append('localization.lat', this.form_for_current_sector.localization.lat)
          formData.append('localization.lng', this.form_for_current_sector.localization.lng)
          break
        default:
          console.log('default')
          break
      }

      for (let index = 0; index < files.length; index++) {
        const file = files[index]
        formData.append(`attached_files_${index}`, file)
      }
      formData.append('password', this.form_for_current_sector.password)
      formData.append('password_confirmation', this.form_for_current_sector.password)

      // console.log('::: ⭕️----------------')
      // for (const pair of formData.entries()) {
      //   console.log(`${pair[0]}: ${pair[1]}`)
      // }
      // console.log('::: ⭕️----------------')

      this.action_createEtablissement(formData)
        .then(result => {
          // console.log("result::: ❌", result);

          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Inscription reussie',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.hideRegisterModal()
          setTimeout(() => {
            this.isRegisteredSuccessfully = true
          }, 1000)
        })
        .catch(err => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    // on_change_file() {
    //   for (let index = 0; index < this.piecesJointesList.length; index++) {
    //     const pieces = this.piecesJointesList[index];
    //     if (this.piecesJointesList[index].value) {
    //       // console.log("pieces::: ", pieces);
    //       // this.handleFileChange(this.piecesJointesList[index].value, index);
    //       const reader = new FileReader();
    //       reader.onload = (e) => {
    //         this.piecesJointesList[index].fileContent = e.target.result;
    //         console.log(
    //           "this.piecesJointesList[index].fileContent::: ",
    //           this.piecesJointesList[index].fileContent
    //         );
    //       };
    //       reader.readAsDataURL(this.piecesJointesList[index].value);
    //     }
    //   }
    // },
    togglePasswordInputType() {
      // eslint-disable-next-line no-unused-expressions
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    showRegisterModal() {
      this.$refs['modal-register'].show()
    },
    hideRegisterModal() {
      this.$refs['modal-register'].hide()
    },
  },
}
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss">
// @import '@core/scss/vue/pages/page-auth.scss';
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Parcourir";
}

.invalid-input .vs__dropdown-toggle {
  border-color: #f3706a;
}

// .password-field {
//   position: relative;
//   .invalid-feedback.password-rules {
//     position: absolute;
//     background: white;
//     border: 1px solid;
//     top: -170px;
//     left: 0;
//     z-index: 9;
//     box-shadow: 1px 1px 6px 0px #00000042;
//     border-radius: 5px;
//   }
// }
.padng {
  padding: 4px 0;
}

.required-field {
  color: red;
  margin-left: 2px;
  /* Adjust the margin as needed */
}
.required-img {
  border-radius: 30px;
  aspect-ratio: 16/9;
  margin: 10px 0;
}
</style>
